import { createSlice } from '@reduxjs/toolkit';
import { ScimLogRecord } from '../models/scim-log-record.model';

const initialScimLogsState: ScimLogState = {
  logs: [],
  logsTotalCount: 0,
  logsPageCount: 0,
  retryIsEnabled: false,
};

const scimHistorySlice = createSlice({
  name: 'scim-history',
  initialState: initialScimLogsState,
  reducers: {
    handleGetLogsPaginated(state, action) {
      state.logs = action.payload.items;
      state.logsTotalCount = action.payload.totalItemsCount;
      state.logsPageCount = Math.ceil(action.payload.totalItemsCount/action.payload.pageSize);
    },
    handleGetRetryTreatment(state, action) {
      state.retryIsEnabled = action.payload;
    },
  },
});

export interface ScimLogState {
  logs: ScimLogRecord[];
  logsTotalCount: number;
  logsPageCount: number;
  retryIsEnabled: boolean;
}

export const scimHistoryActions = scimHistorySlice.actions;

export default scimHistorySlice.reducer;
