import { createSlice } from '@reduxjs/toolkit';

const initialTokenState: TokenState = {
  token: null,
  errors: null,
  isLoading: false,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState: initialTokenState,
  reducers: {
    handleGetToken(state, action) {
      state.token = action.payload.token;
      state.errors = action.payload.errors;
      state.isLoading = false;
    },
    handleResetState(state) {
        state.token = null;
        state.errors = null;
        state.isLoading = false;
      },
    handleSetLoading(state) {
        state.isLoading = true;
        state.token = null;
        state.errors = null;
    },
  },
});

export interface TokenState {
  token: string|null,
  errors: string|null,
  isLoading: boolean,
}

export const tokenActions = tokenSlice.actions;

export default tokenSlice.reducer;
