import { TokenFormComponent } from '../components/token-form.component';
import './AzureOkta.scss';
import React from 'react';

export const AzurePage = () => {
  return (<div className="permanent-token-page" data-testid="azure-page">
    <h2 className="token-page-header">Azure configuration page</h2>
    <div className="page-instructions">
      <div className="step">
        <span className="step-order">Step 1:</span>
        <p className="step-text link">Go to
          <a href="https://portal.azure.com/#home" target="_blank" rel="noreferrer">Azure Portal Home Page.</a>
        </p>
      </div>
      <div className="step">
        <span className="step-order">Step 2:</span>
        <p className="step-text">Find and navigate to <b>Azure Active Directory.</b></p>
      </div>
      <div className="step">
        <div className="step-order">Step 3:</div>
        <div className="step-text">
          Click <b>Enterprise Applications</b> on the side bar, then <b>New application </b>
          and <b>Create your own application.</b>
        </div>
      </div>
      <div className="step">
        <span className="step-order">Step 4:</span>
        <p className="step-text">
          Type any name of the application you like and choose <b>"Integrate any other application...(Non-gallery)".</b>
        </p>
      </div>
      <div className="step">
        <span className="step-order">Step 5:</span>
        <p className="step-text">
          Open created application, navigate via side bar to <b>Provisioning</b> section and configure it choosing
          <b> Automatic</b> mode.
        </p>
      </div>
      <div className="step">
        <span className="step-order">Step 6:</span>
        <p className="step-text">
          Copy <b>Tenant URL</b> from this page below, and enter username and password to obtain and paste token
          in the <b>Secret token</b> field. Save the changes.
        </p>
      </div>
    </div>
    <div className="note">
      <div className="note-text">
        <b>Note:</b> Azure updates will be made on behalf of the user whose credentials were used to generate the token.
        Please make sure that the user has necessary privileges to create and edit users and groups.
      </div>
    </div>
    <TokenFormComponent showOnlyInputs={false} showTenantUrl={true} />
    <div className="page-instructions">
      <div className="step">
        <span className="step-order">Step 7:</span>
        <p className="step-text">
          Navigate to <b>Users and groups</b> section in the side bar and assign all users and groups you want to
          provision. They will be pushed soon after. Any further updates will be pushed automatically by Azure.
        </p>
      </div>
    </div>
    <div className="note">
      <div className="note-text">
        <b>Note:</b> Azure requests will be recorded as a part of "SCIM" Sync in the Sync History Table.
      </div>
    </div>
  </div>)
}
