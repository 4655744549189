import { BaseEnum } from "./base.enum";

export class SchemaType extends BaseEnum {
  public fileTemplateName: string;

  private constructor({id, name, fileTemplateName}: {id: number, name: string, fileTemplateName: string}) {
    super({id, name});
    this.fileTemplateName = fileTemplateName;
  }

  public static readonly UpdateUsername = new SchemaType({id: 1, name: "Update Username", fileTemplateName:"update-usernames"});
  public static readonly ImportUsers = new SchemaType({id: 2, name: "Import Users", fileTemplateName:"import-users"});
  public static readonly UpdateGroupName = new SchemaType({id: 3, name: "Update Group Name", fileTemplateName: "update-groupnames"});
  public static readonly ImportGroups = new SchemaType({id: 4, name: "Import Groups", fileTemplateName:"import-groups"});
  public static readonly SCIM = new SchemaType({id: 5, name: "SCIM", fileTemplateName:"scim"});

  public static GetAll(): SchemaType[] {return [this.UpdateUsername, this.ImportUsers, this.UpdateGroupName, this.ImportGroups, this.SCIM];}
  public static GetAllToDisplay(): SchemaType[] {return [this.UpdateUsername, this.ImportUsers, this.UpdateGroupName, this.ImportGroups];}
}
