import axios, { AxiosInstance } from 'axios';
import { FileImportDataSource, FileImportDataSourceImpl } from './app/datasources/file-import.datasource';
import { FileTemplatesDataSource, FileTemplatesDataSourceImpl } from './app/datasources/file-templates.datasource';
import { SyncSessionsDataSource, SyncSessionsDataSourceImpl } from './app/datasources/sync-sessions.datasource';
import { TokenDataSource, TokenDataSourceImpl } from './app/datasources/token.datasource';
import { WebformsTokenDataSource, WebformsTokenDataSourceImpl } from './app/datasources/webforms-token.datasource';
import { FileImportRepository, FileImportRepositoryImpl } from './app/repositories/file-import.repository';
import { FileTemplatesRepositoryImpl, FileTemplatesRepository } from './app/repositories/file-templates.repository';
import { MemoryRepository, MemoryRepositoryImpl } from './app/repositories/memory.repository';
import { SyncSessionsRepository, SyncSessionsRepositoryImpl } from './app/repositories/sync-sessions.repository';
import { TokenRepository, TokenRepositoryImpl } from './app/repositories/token.repository';
import { WebformsTokenRepository, WebformsTokenRepositoryImpl } from './app/repositories/webforms-token.repository';
import { ScimHistoryRepository, ScimHistoryRepositoryImpl } from './app/repositories/scim-history.repository';
import { ScimHistoryDatasource, ScimHistoryDataSourceImpl } from './app/datasources/scim-history.datasource';
import { FeatureToggleService, StubFeatureToggleService } from './app/datasources/feature-toggle.datasource';
import { AuditTrailRepository, AuditTrailRepositoryImpl } from './app/repositories/audit-trail.repository';
import { AuditTrailDataSource, AuditTrailDatasourceImpl } from './app/datasources/audit-trail.datasource';
import { EntityRetryDataSource, EntityRetryDataSourceImpl } from './app/datasources/entity-retry.datasource';
import { EntityRetryRepository, EntityRetryRepositoryImpl } from './app/repositories/entity-retry.repository';

function initializeContainer() {
    const httpClient: AxiosInstance = initializeAxiosClient();

    const fileImportDataSource: FileImportDataSource = new FileImportDataSourceImpl(httpClient);
    const webformsTokenDataSource: WebformsTokenDataSource = new WebformsTokenDataSourceImpl(httpClient);
    const syncSessionsDataSource: SyncSessionsDataSource = new SyncSessionsDataSourceImpl(httpClient);
    const fileTemplatesDataSource: FileTemplatesDataSource = new FileTemplatesDataSourceImpl();
    const tokenDataSource: TokenDataSource = new TokenDataSourceImpl(httpClient);
    const scimHistoryDataSource: ScimHistoryDatasource = new ScimHistoryDataSourceImpl(httpClient);
    const auditTrailDataSource: AuditTrailDataSource = new AuditTrailDatasourceImpl(httpClient);
    const entityRetryDataSource: EntityRetryDataSource = new EntityRetryDataSourceImpl(httpClient);

    let featureToggleService: FeatureToggleService = new StubFeatureToggleService(); //is replaced in the index.tsx if injected externally

    const memoryRepository: MemoryRepository = new MemoryRepositoryImpl();
    const webformsTokenRepository: WebformsTokenRepository = new WebformsTokenRepositoryImpl(webformsTokenDataSource, memoryRepository);
    const fileImportRepository: FileImportRepository = new FileImportRepositoryImpl(fileImportDataSource, memoryRepository);
    const syncSessionsRepository: SyncSessionsRepository = new SyncSessionsRepositoryImpl(syncSessionsDataSource, memoryRepository);
    const fileTemplatesRepository: FileTemplatesRepository = new FileTemplatesRepositoryImpl(fileTemplatesDataSource);
    const tokenRepository: TokenRepository = new TokenRepositoryImpl(tokenDataSource, memoryRepository);
    const scimHistoryRepository: ScimHistoryRepository = new ScimHistoryRepositoryImpl(scimHistoryDataSource, memoryRepository);
    const auditTrailRepository : AuditTrailRepository = new AuditTrailRepositoryImpl(auditTrailDataSource, memoryRepository)
    const entityRetryRepository : EntityRetryRepository = new EntityRetryRepositoryImpl(entityRetryDataSource, memoryRepository)

    return {
        fileImportRepository,
        memoryRepository,
        syncSessionsRepository,
        webformsTokenRepository,
        fileTemplatesRepository,
        tokenRepository,
        scimHistoryRepository,
        featureToggleService,
        auditTrailRepository,
        entityRetryRepository,
        setFeatureToggleService: (_featureToggleService: FeatureToggleService) => {featureToggleService = _featureToggleService;},
    }
}

function initializeAxiosClient(): AxiosInstance {
    var client = axios.create({
        withCredentials: true,
        validateStatus: function (status) {
          return status >= 200 && status < 500;
        },
      });

      client.interceptors?.request?.use?.(function addCsrfToHeaders(config : any) {
        // @ts-ignore
        const app = window.top.store?.getState?.()?.app;

        config.headers['CSRF-TOKEN'] = app?.csrfToken;
        config.headers['X-CSRF-HASH'] = app?.xCsrfHash;
        return config;
      });

      return client;
}

export default initializeContainer();
