import { faUser, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useMemo, useState } from "react";
import { AzureIcon } from "../assets/icons/azure.icon";
import { OktaIcon } from "../assets/icons/okta.icon";
import './import-navigation-panel.scss';
import { FileIcon } from "../assets/icons/file.icon";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Tooltip from "./tooltip.component";

interface SubTab {
  icon: ReactElement;
  label: string;
  id: string;
  tabId: string;
  description: string;
  linkTo: string;
}

interface Tab {
  icon: ReactElement;
  label: string;
  id: string;
  subTabs: SubTab[];
  linkTo: string | null;
  isActive: boolean;
}

export const ImportNavigationPanel = () => {

  const activeIconColor = useMemo(() => '#0089d6', []);

  const routePrefix = useSelector((state: any) => state.main.routePrefix);
  const linkButtons: Tab[] = [
    {
      icon: <FileIcon color={activeIconColor} />,
      label: "File Sync",
      id: "import-file-btn",
      linkTo: null,
      isActive: true,
      subTabs: [
        {
          icon: <FontAwesomeIcon icon={faUser} />,
          label: "Sync Users",
          description: "Sync users by mapping values within a supported file.",
          id: "import-file-users-btn",
          tabId: "import-file-btn",
          linkTo: `${routePrefix}/import/users`
        },
        {
          icon: <FontAwesomeIcon icon={faUsers} />,
          label: "Sync Groups",
          description: "Sync groups by mapping values within a supported file.",
          id: "import-file-groups-btn",
          tabId: "import-file-btn",
          linkTo: `${routePrefix}/import/groups`
        },
      ]
    },
    {
      icon: <AzureIcon color={activeIconColor} />,
      label: "Azure AD",
      id: "import-azure-btn",
      isActive: true,
      subTabs: [],
      linkTo: `${routePrefix}/azure`
    },
    {
      icon: <OktaIcon color={activeIconColor} />,
      label: "Okta",
      id: "import-okta-btn",
      isActive: true,
      subTabs: [],
      linkTo: `${routePrefix}/okta`
    },
  ];
  let allSubTabs: SubTab[] = [];
  linkButtons.forEach(lb => { allSubTabs = allSubTabs.concat(lb.subTabs); });

  const [visibleSubTabs, setVisibleSubTabs] = useState<SubTab[]>([]);

  const reRenderSubTabs = (tabId: string) => {
    const subTabsToRender = linkButtons
      .find(t => t.id === tabId)
      ?.subTabs ?? [];

    //@ts-ignore
    setVisibleSubTabs(subTabsToRender);
  }

  const tabsBody = linkButtons.map(lb => {
    const el =
    <button className="action-button" data-testid={lb.id} id={lb.id} key={lb.id} disabled={!lb.isActive} onClick={(e) => { e.preventDefault(); if (lb.linkTo === null) reRenderSubTabs(lb.id); }}>
      <Link to={`${lb.linkTo ?? '#'}`} key={`${lb.id}-link`}>
        {lb.icon}
        <label key={`${lb.id}-label`}>{lb.label}</label>
      </Link>
    </button>;
    return lb.isActive
    ? el
    : <Tooltip element={el} title="Coming soon!" />
  }
  );

  const subTabsBody = visibleSubTabs?.map(st =>
    <Link to={st.linkTo} className="sub-tab" id={st.id} key={st.id}>
      <p className="logo">{st.icon}</p>
      <p className="header">{st.label}</p>
      <p>{st.description}</p>
    </Link>)

  return (<div className="import-navigation-panel">
    <p>Select a provider</p>
    <div className="import-tabs-panel">
      {tabsBody}
    </div>
    <div className="import-subtabs-panel" style={{minHeight: subTabsBody.length > 0 ? "192px" : ""}}>
      {subTabsBody}
    </div>
  </div>);
}
