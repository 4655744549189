import React, { useEffect } from 'react';
import InfoTooltip from '../components/info-tooltip.component';
import ModalWrapper from '../components/modal-wrapper-component';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile, saveFileMappings, getSchemaMappings, resetFileData } from '../store/import-actions';
import { SchemaType } from '../models/enums/schema-type.enum';
import { Redirect } from 'react-router';
import { DownloadSchemaFileLink } from './download-schema-file-link.component';
import './file-import-form.scss';

export interface EventInterface {
    target: any
}

export const FileImportForm = ({ importSchema }: { importSchema: SchemaType }) => {
    const isInDevelopmentMode = useSelector((state: any) => state.main.isInDevelopmentMode);
    const RoutePrefix = isInDevelopmentMode ? '' : '/sync';
    const dispatch = useDispatch();
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isRecurrent, setIsRecurrent] = React.useState(false);

    const [formData, setFormData] = React.useState({});
    const [syncName, setSyncName] = React.useState('');

    const fileName = useSelector((state: any) => state.import.fileName);
    const fileHeaders = useSelector((state: any) => state.import.fileHeaders);
    const schemaHeaders = useSelector((state: any) => state.import.schemaHeaders ?? []);
    const fileErrors = useSelector((state: any) => state.import.fileErrors);
    const syncId = useSelector((state: any) => state.import.syncId);
    const sessionId = useSelector((state: any) => state.import.sessionId);
    const shouldRedirect: boolean = useSelector((state: any) => state.import.shouldRedirect);

    const requiredHeadersNames = schemaHeaders?.filter((sh: { isRequired: Boolean, name: string }) => sh.isRequired)?.map((sh: { isRequired: Boolean, name: string }) => sh.name) ?? [];

    const allRequiredFieldsFilled: boolean = ((syncName?.length ?? 0) > 0) &&
        //@ts-ignore
        requiredHeadersNames.every((h: string) => (h in formData) && formData[h] !== '');

    const inputFileRef = React.useRef(null);

    useEffect((): void => {
        setSyncName('');
        dispatch(resetFileData());
        dispatch(getSchemaMappings({ schemaType: importSchema }))
    }, [dispatch, importSchema])

    const handleFileChange = (e: EventInterface) => {
        if (e.target.value !== "" || !!e.target.files[0]) {
            setFormData({});
            dispatch(resetFileData());
            //@ts-ignore
            dispatch(uploadFile({ file: inputFileRef?.current?.files[0], schemaType: importSchema }));
        }
    }

    //@ts-ignore
    const handleUploadButtonClick = () => inputFileRef.current.click();

    const handleSyncNameChange = (event: EventInterface) => setSyncName(event.target.value);

    const handleMappingsReset = () => { setFormData({}) }
    const handleFormReset = () => { setFormData({}); dispatch(resetFileData()); setSyncName(''); }

    const handleModalOpen = (e: any) => {
        e.preventDefault();
        setIsModalOpen(true);
    };

    const handleModalSubmit = (e: any) => {
        dispatch(saveFileMappings({ syncId: syncId, mappingsJson: JSON.stringify(formData), syncName: syncName }))
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleSelectChange = (e: EventInterface) => {
        const { name, value } = e.target;
        //@ts-ignore
        setFormData({ ...formData, [name]: value });
    };

    const disableMappings = !fileName;

    const mappingColumns = schemaHeaders?.map(({ name, displayName, isRequired, info }: { name: string, displayName: string, isRequired: boolean, info: string }) =>
        <li key={displayName}>
            <p>{displayName}{isRequired && <span className="required">*</span>} {!!info && <InfoTooltip title={displayName} subtitle={info} />}</p>
            <select name={name} required={isRequired} disabled={disableMappings} id={`ii-${name}`} onChange={handleSelectChange}>
                <option value="" />
                {fileHeaders.map((value: string) => <option key={value} value={value}>{value}</option>)}
            </select>
        </li>
    ) ?? []

    return (
        <section className="sectionWrapper">
            {
                shouldRedirect && isRecurrent
                &&
                <Redirect to={`${RoutePrefix}/session/${sessionId}/recurrent`}></Redirect>
            }
            {
                shouldRedirect && !isRecurrent
                &&
                <Redirect to={RoutePrefix}></Redirect>
            }
            <div className="fileSyncPage">
                <div className="import-disclaimer">
                    <label>Note: All unsaved configuration changes for current sync will be discarded once this page is closed.</label>
                </div>
                {fileErrors.length > 0 && 
                <div id="importErrors" className={fileErrors.length > 0 ? "errorsTable" : ""} style={{ marginBottom: "10px" }}>
                    <ul>
                        {fileErrors.map((e: string) =>
                            <li key={fileErrors.indexOf(e)} className="importError">{e}</li>
                        )}
                    </ul>
                </div>
                }
                <form className="fileSyncForm">
                    <div className="fileSyncForm--row">
                        <label className="fileSyncForm--label">
                            Select File:
                            <span className="required">*</span>

                            <InfoTooltip
                                title="Select File"
                                subtitle="Select and upload a supported file type (.csv) to sync new users."
                            />
                        </label>

                        <div style={{ display: "flex" }}>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                ref={inputFileRef}
                                onChange={handleFileChange}
                            />

                            <input
                                id="fileImport-uploadButton"
                                className="uploadButton buttonPrimary"
                                type="button"
                                value="Choose File"
                                onClick={handleUploadButtonClick}
                            />

                            <DownloadSchemaFileLink importSchema={importSchema} linkName="Download Template" />
                        </div>
                    </div>

                    {!!fileName && (
                        <div className="fileSyncForm--row">
                            <label className="fileSyncForm--label" />
                            <em className="fileSyncForm--fileName break-line">{fileName}</em>
                        </div>
                    )}

                    <div className="fileSyncForm--row">
                        <label className="fileSyncForm--label">
                            Sync Name:
                            <span className="required">*</span>
                        </label>
                        <ul className="fieldsGrid">
                            <li>
                                <input type="text" id="syncName" name="syncName" value={syncName as unknown as string} onChange={handleSyncNameChange} />
                            </li>
                        </ul>
                    </div>

                    {schemaHeaders?.length > 0 &&
                        <>
                            <div className="fileSyncForm--row">
                                <label className="fileSyncForm--label">
                                    Field Mappings:
                                    <InfoTooltip
                                        title="Field Mappings"
                                        subtitle="Map the values in your file with the appropriate PowerDMS labels below."
                                    />
                                </label>
                                <div>
                                    <ul className="fieldsGrid">
                                        {mappingColumns}
                                    </ul>
                                    <button className="buttonSecondary link-button" type="reset" onClick={handleMappingsReset} style={{ fontWeight: 400, marginTop: "5px" }}>Clear fields</button>
                                </div>
                            </div>

                        </>
                    }

                    <div className="fileSyncForm--row">
                        <label className="fileSyncForm--label" />
                        <div className="fileSyncForm--buttons">
                            <input name="recurrent-sync-check" type="checkbox" onChange={(e: any) => { setIsRecurrent(e.target.checked) }} style={{ marginTop: "auto", marginBottom: "auto" }} />
                            <label style={{ marginLeft: "5px", color: "black" }}>Setup reccurent sync <InfoTooltip title="Recurrent Sync" subtitle="After completing this page you will be immediately prompted to recurrent sync instructions. You still will be able to configure recurrent sync anytime on the sync's page." /></label>
                        </div>
                    </div>

                    <div className="fileSyncForm--row">
                        <label className="fileSyncForm--label" />
                        <div className="fileSyncForm--buttons">
                            <button className="buttonPrimary" onClick={handleModalOpen} disabled={!allRequiredFieldsFilled}>Run Sync</button>
                            <button className="buttonSecondary" type="reset" onClick={handleFormReset}>Reset</button>
                        </div>
                    </div>
                </form>

                <ModalWrapper isOpen={isModalOpen} onClose={handleModalClose}>
                    <h2 className="sync-modal--title">Run Sync Now</h2>
                    <p className="sync-modal--text">You are about to run your file sync. Progress can be viewed in the Sync History section of the User Sync page.</p>
                    <p className="sync-modal--text">Are you ready to run sync?</p>

                    <div className="sync-modal--buttons">
                        <button className="buttonPrimary" onClick={handleModalSubmit}>Run Sync</button>
                        <button className="buttonSecondary link-button" onClick={handleModalClose}>Cancel</button>
                    </div>
                </ModalWrapper>
            </div>
        </section>
    );
};

