import { HttpResponseModel } from "../../models/shared/http-response.model";
import { TokenResponseModel } from "../../models/token-response.model";
import { TokenDataSource } from "../datasources/token.datasource";
import { MemoryRepository } from "./memory.repository";

export interface TokenRepository {
    getToken({userName, password, siteKey}: {userName: string, password: string,  siteKey: string}): Promise<HttpResponseModel<TokenResponseModel>>;
}

export class TokenRepositoryImpl implements TokenRepository {
    constructor(
        private dataSource: TokenDataSource, 
        private memoryRepository: MemoryRepository
        ) { };

    async getToken({userName, password, siteKey}: {userName: string, password: string, siteKey: string}): Promise<HttpResponseModel<TokenResponseModel>> {
        const cookieBasedToken: string = await this.memoryRepository.getWebFormsToken();
        const result = this.dataSource.getToken({userName, password, siteKey, cookieBasedToken});
        return result;
    }
}