import container from '../infrastructure';
import { auditActions } from "./audit";
import { AuditTrailPaginated } from '../app/repositories/audit-trail.repository'
import { PaginatedResponseModel } from '../models/shared/paginated-response.model';
import { AuditTrailRecord } from '../models/audit-trail-record.model';

export const getAuditTrailPaginated = ({pageSize, pageNumber, sortingField, isAscending, filters}: AuditTrailPaginated) => {
    return async (dispatch: Function) => {
      try {
        const response: PaginatedResponseModel<AuditTrailRecord> = await container.auditTrailRepository.getAuditTrailPaginated({pageSize, pageNumber, sortingField, isAscending, filters})
        dispatch(auditActions.handleGetAuditTrail(response));
      } catch (err) {
        console.error(`Error retrieving data from syncApi with message ${err}`);
        dispatch(auditActions.handleSetError(err));
      }
    };
  };