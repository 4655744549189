import { SyncLogSeverity } from "./enums/sync-log-severity.enum";

export class SyncLogRecord {
    public fileRowNumber: string = null as unknown as string;
    public status: SyncLogSeverity = null as unknown as SyncLogSeverity;
    public messages: string[] = [];
    public lastModifiedDate: Date = null as unknown as Date;
    public targetedObjectName: string = null as unknown as string;

    public constructor({ fileRowNumber, targetedObjectName, status, messages, lastModifiedDate}
        : { fileRowNumber: string, targetedObjectName: string, status: SyncLogSeverity, messages: string[], lastModifiedDate: Date}) {
            this.fileRowNumber = fileRowNumber;
            this.targetedObjectName = targetedObjectName;
            this.status = status;
            this.messages = messages;
            this.lastModifiedDate = lastModifiedDate;
        }
    
    public static fromJson(json: any): SyncLogRecord {
        var dto = JSON.parse(json);
        return this.fromJObj(dto);
    }
    public static fromJObj(dto: any): SyncLogRecord {
        return new SyncLogRecord ({
            fileRowNumber: dto['fileRowNumber'],
            targetedObjectName: dto['targetedObjectName'],
            messages: dto['messages'],
            lastModifiedDate: new Date(dto["lastModifiedDate"]),
            status: SyncLogSeverity.GetById(parseInt(dto["overallStatus"])) as SyncLogSeverity
        })
    }
}