import { faRedoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Column, useFilters, usePagination, useSortBy, useTable } from "react-table"
import './table.component.scss';

export interface TableProps {
    columns: Column[],
    hiddenColumns?: string[],
    data: any[],
    tableId: string,
    requestDataCallBack: any, //change to typed lambda?
    refreshDataTrigger?: any,
    totalCount: number,
    pageCounter: number,
    dataPayload: any,
    showRefreshButton: boolean,
    requestDataWithoutDispatch?: boolean,
    emptyTableText?: string,
}

const defaultPageSize = 25;

export const Table = ({ columns, data, tableId, requestDataCallBack, totalCount, pageCounter, dataPayload, refreshDataTrigger, showRefreshButton, requestDataWithoutDispatch, hiddenColumns, emptyTableText }: TableProps) => {
    const dispatch = useDispatch();
    const [refreshDataClick, setRefreshDataClick] = useState(false);

    const pageSizeOptions = useMemo(() => {
        return [25, 50, 100, 200];
    }, [])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        //@ts-ignore
        page, canPreviousPage, canNextPage, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize }
    } = useTable({
        columns,
        data,
        //@ts-ignore
        initialState: { pageIndex: 0, pageSize: defaultPageSize, hiddenColumns: hiddenColumns || [] },
        autoResetSortBy: false,
        manualPagination: true,
        autoResetFilters: false,
        manualFilters: true,
        pageCount: pageCounter,
        manualSortBy: true,
        disableMultiSort: true //don't know if we need this
    },
        useFilters,
        useSortBy,
        usePagination
    );

    // passing "headerGroups" in the next useMemo as dependency doesn't work correctly. Probably because we use map() so new array is created each time
    //@ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const headerTrackers = headerGroups[0].headers.map(h => { return { isSorted: h.isSorted, isSortedDesc: h.isSortedDesc, id: h.id } }) ?? [];

    const sortingHeader: { sortingField: string | null, isAscending: boolean } = useMemo(() => {
        if (headerGroups.length > 0) {
            const activeHeader: any = headerTrackers.find(h => h.isSorted) ?? { id: null, isAscending: true };
            return { sortingField: activeHeader.id as string, isAscending: !activeHeader.isSortedDesc as boolean }
        }
        return { sortingField: null, isAscending: true };
    }, [headerTrackers, headerGroups.length])

    const filters = headerGroups[0].headers.map(r => {
        return {
            id: r.id,
            //@ts-ignore
            filterValue: r.filterValue
        }
    }).filter(f => f.filterValue);
    const filtersJson = useMemo(() => JSON.stringify(filters), [filters]);

    useEffect(() => {
        // we need this line only to include reloadDataTrigger as a list of dependencies, otherwise linter goes crazy
        window.sessionStorage["refreshDataTrigger"] = refreshDataTrigger;
        const payload = {
            ...dataPayload,
            pageNumber: pageIndex,
            pageSize: pageSize,
            sortingField: sortingHeader?.sortingField,
            isAscending: sortingHeader?.isAscending,
            filters: JSON.parse(filtersJson)
        }

        if (requestDataWithoutDispatch)
            requestDataCallBack(payload)
        else
            dispatch(requestDataCallBack(payload));
    }, [dispatch, pageIndex, pageSize, sortingHeader?.sortingField, sortingHeader?.isAscending, requestDataCallBack, dataPayload, refreshDataTrigger, refreshDataClick, requestDataWithoutDispatch, filtersJson])

    const tableHeaderLabel = useMemo(() =>
        <div style={{ width: "100%", textAlign: "right" }}>
            <label className="table-header-label"> 
                {
                    data.length > 0
                        ? `Showing items ${1 + pageSize * (pageIndex)}-${Math.min(totalCount, (pageSize) * (pageIndex + 1))} of ${totalCount}`                        
                        : '0 items found'
                }
            </label>
        </div>
        , [pageSize, pageIndex, totalCount, data.length]);

    const handleRefreshClick = useMemo(() => {
        return (e: any) => { e.preventDefault(); setRefreshDataClick(!refreshDataClick); }
    }, [refreshDataClick]);

    return (<>
        {
            <div className="table-container">
                <div>
                    {showRefreshButton &&
                        <button className="action-button" style={{ marginLeft: "auto", display: "block" }} onClick={handleRefreshClick}>
                            <FontAwesomeIcon icon={faRedoAlt} style={{ marginRight: "5px" }} /> Refresh Logs
                        </button>
                    }
                </div>
                {data.length > 0 || filters.length > 0 ?
                    <div>
                        {tableHeaderLabel}
                        <div className="sync-table-container">
                            <table id={tableId} {...getTableProps()} className="sync-table">
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                //@ts-ignore
                                                <th {...column.getHeaderProps({ ...column.getSortByToggleProps(), style: { width: column.width } })}>
                                                    <div className="flex" style={{ alignItems: "center" }}>
                                                        {column.render('Header')}
                                                        <span style={{ marginRight: "5px" }}>
                                                            {/*@ts-ignore*/}
                                                            {column.isSorted ? column.isSortedDesc
                                                                ? ' ▼'
                                                                : ' ▲'
                                                                : ''}
                                                        </span>
                                                        {/*@ts-ignore*/}
                                                        {column.Filter ? column.render('Filter') : null}
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {/*@ts-ignore*/}
                                    {page.map((row) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {/*@ts-ignore*/}
                                                {row.cells.map(cell => {
                                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                })}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="table-footer" style={{ marginTop: "5px" }}>
                                <div>
                                    <button onClick={(event: any) => { event.preventDefault(); gotoPage(0); }} disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>{' '}
                                    <button onClick={(event: any) => { event.preventDefault(); previousPage(); }} disabled={!canPreviousPage}>
                                        {'<'}
                                    </button>{' '}
                                    <button onClick={(event: any) => { event.preventDefault(); nextPage(); }} disabled={!canNextPage}>
                                        {'>'}
                                    </button>{' '}
                                    <button onClick={(event: any) => { event.preventDefault(); gotoPage(pageCount - 1); }} disabled={!canNextPage}>
                                        {'>>'}
                                    </button>{' '}
                                </div>
                                <div style={{ float: "right" }}>
                                    <label>Items per page:</label>
                                    <select onChange={event => setPageSize(parseInt(event.target.value))}>
                                        {
                                            pageSizeOptions.map(opt => <option key={opt} value={opt}>{opt}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div style={{ display: "grid" }}>
                        <label className="empty-table-message" style={{ textAlign: "center" }}>{emptyTableText || "No data yet!"}</label>
                    </div>
                }
            </div>
        }
    </>
    )
}
