export class FileValidationModel {
    public sessionId: string|null = null;
    public syncId: string|null = null;
    public validationResult: {
        isSuccessful: boolean;
        errorMessages: string[];
    } = { isSuccessful: false, errorMessages: [] };
    public fileHeaders: string[] = [];

    public constructor({ sessionId, syncId, validationResult, fileHeaders }
        : {
            sessionId: string|null,
            syncId: string|null,
            validationResult: { isSuccessful: boolean; errorMessages: string[]; },
            fileHeaders: string[]
        }) {
        this.sessionId = sessionId;
        this.syncId = syncId;
        this.fileHeaders = fileHeaders;
        this.validationResult = validationResult;
    }

    public static fromJson(json: any): FileValidationModel {
        var dto = JSON.parse(json);
        return new FileValidationModel ({
            sessionId: dto['sessionId'],
            syncId: dto['syncId'],
            validationResult: dto['validationResult'],
            fileHeaders: dto['fileHeaders'],
        })
    }
}