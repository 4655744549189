import { ScimLogStatus, ScimOperationType } from './enums/scim-log.enum';

type ScimLogType = {
  requestId: string,
  targetIdentifier: string,
  status: ScimLogStatus,
  operationType: any,
  messages: string[],
  lastModifiedDate: Date,
}

export class ScimLogRecord {
  public requestId: string | null = null;
  public status: ScimLogStatus = null as unknown as ScimLogStatus;
  public operationType: ScimOperationType = null as unknown as ScimOperationType;
  public messages: string[] = [];
  public lastModifiedDate: Date = null as unknown as Date;
  public targetIdentifier: string = null as unknown as string;

  public constructor({ requestId, targetIdentifier, status, operationType, messages, lastModifiedDate}: ScimLogType) {
    this.requestId = requestId;
    this.targetIdentifier = targetIdentifier;
    this.status = status;
    this.operationType = operationType;
    this.messages = messages;
    this.lastModifiedDate = lastModifiedDate;
  }

  public static fromJson(json: any): ScimLogRecord {
    const dto = JSON.parse(json);

    return this.fromJObj(dto);
  }
  public static fromJObj(dto: any): ScimLogRecord {
    return new ScimLogRecord ({
      requestId: dto['requestId'],
      targetIdentifier: dto['targetIdentifier'],
      messages: dto['messages'],
      lastModifiedDate: new Date(dto['lastModifiedDate']),
      status: ScimLogStatus.GetById(parseInt(dto['status'])) as ScimLogStatus,
      operationType: ScimOperationType.GetById(parseInt(dto['operationType'])) as ScimOperationType,
    })
  }
}
