import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './info-tooltip.component.scss';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export interface InfoTooltipInterface {
  title: string,
  subtitle: string
}

const InfoTooltip = (props: InfoTooltipInterface) => {
  return <Tooltip title={props.title} subtitle={props.subtitle} icon={faInfoCircle} />;
};

interface TooltipInterface {
  title: string,
  subtitle: string
  icon: IconProp;
  iconStyle?: React.CSSProperties
}

export const Tooltip = (props: TooltipInterface) => {
  const { title, subtitle, icon, iconStyle } = props;

  if (!title || !subtitle) {
    return null;
  }

  return (
    <div className="infoTooltip">
      <span className="icon">
        <FontAwesomeIcon icon={icon} style={iconStyle} />
      </span>

      <div className="tooltip">
        <p className="title">{title}</p>
        <p className="subtitle">{subtitle}</p>
      </div>
    </div>
  );
}

export default InfoTooltip;
