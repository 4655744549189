export abstract class BaseEnum {
    public id: number;
    public name: string;
    protected constructor({ id, name }: { id: number, name: string }) {
        this.id = id;
        this.name = name;
    }

    public static GetAll(): BaseEnum[] { return []; }
    public static GetById(id: number): BaseEnum | undefined { return this.GetAll().find((st: BaseEnum) => st.id === id); }
    public static GetByName(name: string): BaseEnum | undefined { return this.GetAll().find((st: BaseEnum) => st.name === name); }
}