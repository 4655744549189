import { PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { MemoryRepository } from './memory.repository';
import { ScimHistoryDatasource } from '../datasources/scim-history.datasource';
import { ScimLogRecord } from '../../models/scim-log-record.model';
import { HttpResponseModel } from '../../models/shared/http-response.model';

export type ScimLogsPaginated = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
}

export interface ScimHistoryRepository {
  getScimLogsPaginated({ pageSize, pageNumber, sortingField, isAscending }: ScimLogsPaginated)
    : Promise<PaginatedResponseModel<ScimLogRecord>>;
  cancelResumeScimSync({enableProcessing}: { enableProcessing: boolean }): Promise<HttpResponseModel<string>>;
  retryScimSync(sessionId:string ): Promise<HttpResponseModel<string>>; // TODO: fix depending on backend
}


export class ScimHistoryRepositoryImpl implements ScimHistoryRepository {
  constructor(
    private dataSource: ScimHistoryDatasource,
    private memoryRepository: MemoryRepository,
  ) {}

  async getScimLogsPaginated({ pageSize, pageNumber, sortingField, isAscending }: ScimLogsPaginated)
    : Promise<PaginatedResponseModel<ScimLogRecord>> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();

      return await this.dataSource.getScimLogsPaginated(
        { pageSize, pageNumber, sortingField, isAscending, webFormsToken });
    } catch (error) {
      return null as unknown as PaginatedResponseModel<ScimLogRecord>;
    }
  }

  async cancelResumeScimSync({enableProcessing}: { enableProcessing: boolean }): Promise<HttpResponseModel<string>> {
    const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
    return await this.dataSource.cancelResumeScimSync({enableProcessing, webFormsToken});
  }

  async retryScimSync(sessionId:string): Promise<HttpResponseModel<string>> {
    const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
    return await this.dataSource.retryScimSync({sessionId,webFormsToken});
  }
}
