import { HttpResponseModel } from "../models/shared/http-response.model";
import container from '../infrastructure';
import { tokenActions } from './token'
import { TokenResponseModel } from "../models/token-response.model";

export const getToken = ({ userName, password, siteKey }: { userName: string, password: string, siteKey: string }) => {
    return async (dispatch: Function) => {
        try {
            dispatch(tokenActions.handleSetLoading());
            const response: HttpResponseModel<TokenResponseModel> = await container.tokenRepository.getToken({ userName, password, siteKey });
            dispatch(tokenActions.handleGetToken(response.data));
        } catch (err) {
            dispatch(tokenActions.handleGetToken({
                token: null,
                errors: (err as any)?.response?.data?.errors ?? ["An error has occured. Please try again later."]
            }));
        }
    };
}

export const resetState = () => {
    return async (dispatch: Function) => {
        dispatch(tokenActions.handleResetState());
    }
}
