import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSyncProxyControllerUrl } from "../services/helper";
import { loadRecurrentSchedulerScript } from "../store/import-actions";
import { TokenFormComponent } from "./token-form.component";
import './recurrent-sync.scss'
import { DownloadFileLink } from "./download-file-link.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export const RecurrentSyncComponent = ({ syncId, schemaType }: { syncId: string, schemaType: string }) => {
    const dispatch = useDispatch();
    const scriptName = useMemo(() => `Recurrent-Import-Scheduler-${syncId}.ps1`, [syncId]);
    const token: string = useSelector((state: any) => state.token.token);
    const endpointUrl = `${getSyncProxyControllerUrl()}/import/csv`;
    const scriptTextTemplate = useSelector((state: any) => state.import.recurrentSchedulerScript);

    const [showCustomScriptSection, setShowCustomScriptSection] = useState(false);

    const [scriptSection, setScriptSection] = useState({
        id: "script",
        title: "Script generation",
        display: true,
        open: true
    });

    const [launchSection, setLaunchSection] = useState({
        id: "launch",
        title: "Launch the script",
        display: true,
        open: false
    });

    const [advancedSection, setAdvancedSection] = useState({
        id: "advanced",
        title: "Advanced commands",
        display: true,
        open: false
    });

    const [customSection, setCustomSection] = useState({
        id: "custom",
        title: "Custom recurrent sync",
        display: false,
        open: true
    });

    useEffect(() => {
        dispatch(loadRecurrentSchedulerScript())
    }, [dispatch]);

    // we need to refresh the page only when user clicks "Confirm credentials"
    const showScriptLink = useMemo(() => !showCustomScriptSection && token, [token]); // eslint-disable-line react-hooks/exhaustive-deps

    const scriptBody = useMemo(() => {
        return scriptTextTemplate
            ?.replace("{0}", syncId)
            .replace("{1}", token)
            .replace("{2}", endpointUrl)
            .replace("{3}", schemaType)
            ?? null;
    }, [scriptTextTemplate, syncId, token, endpointUrl, schemaType]);

    useEffect(() => {
        if (token !== null) {

            if (showCustomScriptSection) {
                setLaunchSection({ ...launchSection, display: false });
                setAdvancedSection({ ...advancedSection, display: false });
                setCustomSection({ ...customSection, display: true });
            }
            else {
                setLaunchSection({ ...launchSection, display: true });
                setAdvancedSection({ ...advancedSection, display: true });
                setCustomSection({ ...customSection, display: false });
            }
        }
    }, [syncId, token, endpointUrl, schemaType, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    const getArrowIcon = (isOpen: boolean) => {
        return (
            <label style={{ textAlign: "left", display: "inline-block", marginRight: "5px" }}>
                {isOpen ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />}
            </label>
        );
    }

    const scriptDownloadCallback = useCallback(() => {
        setLaunchSection({ ...launchSection, open: true });
    }, [launchSection])

    const copyEndpointUrlToClipboard = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(endpointUrl);
    }

    const copyTokenToClipboard = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(token);
    }

    const copySyncIdToClipboard = (e: any) => {
        e.preventDefault();
        navigator.clipboard.writeText(syncId);
    }

    return (
        <div className="recurrent-sync-component">
            <div className="instructions">
                <div className="row" >
                    <button type="button" className="collapsible" onClick={() => setScriptSection({ ...scriptSection, open: !scriptSection.open })}>
                        {getArrowIcon(scriptSection?.open ?? false)}Script generation
                    </button>
                </div>
            </div>
            <div className={`collapsible-content ${scriptSection?.open ?? false ? "active" : ""}`} >
                <div>
                    <label className="step-label"><p className="bold">Step 1:</p> Please provide the credentials of the user, on behalf of which this recurrent sync will be executed</label>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ verticalAlign: "bottom" }}>
                                <td><label className="bold">Note:</label></td>
                                <td><label style={{ paddingLeft: "1px" }}>The user needs to have the following permissions:</label></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <ul style={{ marginTop: "0" }}>
                                        <li><label>Create Users</label></li>
                                        <li><label>Create Groups</label></li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="row">
                        <input type="checkbox" onChange={(e: any) => { setShowCustomScriptSection(e.target.checked) }} checked={showCustomScriptSection} />
                        <label>I will use custom script</label>
                    </div>
                    <div className="token-form-section">
                        <TokenFormComponent showOnlyInputs={true} showTenantUrl={false} />
                    </div>
                    <div style={{ minHeight: "20px" }}>
                        {
                            showScriptLink &&
                            <label style={{ color: "green" }}>Credentials were confirmed and token successfully generated!</label>
                        }
                    </div>
                </div>

                <div style={{ minHeight: "60px" }}>
                    <div style={{ display: (showScriptLink ? "block" : "none") }}>
                        <label className="step-label"><p className="bold">Step 2:</p> Click on the link below to download the script to schedule recurrent file imports.</label>
                        <div className="file-link-row" style={{ margin: "5px" }}>
                            <DownloadFileLink content={scriptBody} linkName={scriptName} fileName={scriptName} onClickCallback={scriptDownloadCallback} />
                        </div>
                    </div>
                </div>
            </div>

            {
                launchSection.display &&
                <>
                    <div className="row" >
                        <button type="button" className="collapsible" onClick={() => setLaunchSection({ ...launchSection, open: !launchSection.open })}>
                            {getArrowIcon(launchSection.open)}Launch the script
                        </button>
                    </div>

                    <div className={`collapsible-content ${launchSection.open ?? false ? "active" : ""}`} >
                        <label className="step-label"><p className="bold">Step 3:</p> Navigate to the downloaded script, and open a command line. Execute the file via command line, passing path to the needed csv file as a parameter.</label>
                        <ul>
                            <li><label>Click the <p className="bold">Search icon</p> (near the Start icon on your taskbar), type <p className="bold">Powershell</p> and press <p className="bold">Enter</p> to open the application.</label></li>
                            <li><label>Type <p className="bold">Powershell -File </p> and leave a space after.</label></li>
                            <li>
                                <label><p className="bold">Prerequisite:</p> Your system needs to have <p className="bold">Execution Policy</p> set to <p className="bold">Unrestricted</p>.</label>
                                <label> You only need to set it once by executing the following command:</label>
                                <div className="code-block">
                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                    <label className="yellow">Set-ExecutionPolicy</label> {" "}
                                    <label className="grey">-ExecutionPolicy</label> {" "}
                                    <label className="white">Unrestricted</label>
                                </div>
                            </li>
                            <li>
                                <label>Drag {"&"} drop downloaded script in the Powershell window. Don't forget to separate path with spaces.</label>
                            </li>
                            <li><label>Now drag {"&"} drop the path to the file to be uploaded. </label>
                                <label style={{ marginBottom: "5px" }}>The result should look similar to this:</label>
                                <div className="code-block">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ verticalAlign: "top" }}>
                                                <td>
                                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                                </td>
                                                <td>
                                                    <label className="yellow">Powershell</label> {" "}
                                                    <label className="grey">-File</label> {" "}
                                                    <label className="white">C:\Users\Admin\Downloads\{`${scriptName}`}</label> {" "}
                                                    <label className="white">"C:\Users\Admin\My Documents\import data.csv"</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li><label>Press <p className="bold">Enter</p> to execute the script. If the system warns you about script being not trusted – select <p style={{ fontStyle: "italic", display: "inline-block", margin: 0 }}>Run once</p> by pressing <p className="bold">R</p> and <p className="bold">Enter</p>.</label></li>
                            <li><label>While recurrent sync is running, it will execute every day approximately at 2 PM, fetching the file from the provided file path. Group Imports are processed first.</label></li>
                        </ul>
                    </div>
                </>
            }
            {
                advancedSection.display &&
                <>
                    <div className="row" >
                        <button type="button" className="collapsible" onClick={() => setAdvancedSection({ ...advancedSection, open: !advancedSection.open })}>
                            {getArrowIcon(advancedSection.open ?? false)}Advanced commands
                        </button>
                    </div>

                    <div className={`collapsible-content ${advancedSection.open ? "active" : ""}`} >
                        <ul>
                            <li>
                                <label><h3>How to check the recurrent sync</h3></label>
                                <label className="label"> If you want to check if the recurrent sync exists, you need to execute this script as above but append <p className="bold">-Mode Check</p> to the end.</label>
                                <label className="label">Optionally you can pass path to the input file (as in original command), then its availability will be checked as well.</label>
                                <label style={{ marginBottom: "5px" }}>Example:</label>
                                <div className="code-block">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ verticalAlign: "top" }}>
                                                <td>
                                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                                </td>
                                                <td>
                                                    <label className="yellow">Powershell</label> {" "}
                                                    <label className="grey">-File</label> {" "}
                                                    <label className="white">C:\Users\Admin\Downloads\{`${scriptName}`}</label> {" "}
                                                    <label className="grey">-Mode</label> {" "}
                                                    <label className="white"> Check</label>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="white">OR</td>
                                                <td></td>
                                            </tr>
                                            <tr style={{ verticalAlign: "top" }}>
                                                <td>
                                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                                </td>
                                                <td>
                                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                                    <label className="yellow">Powershell</label> {" "}
                                                    <label className="grey">-File</label> {" "}
                                                    <label className="white">C:\Users\Admin\Downloads\{`${scriptName}`}</label> {" "}
                                                    <label className="white">"C:\Users\Admin\My Documents\import data.csv"</label> {" "}
                                                    <label className="grey">-Mode</label> {" "}
                                                    <label className="white"> Check</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li>
                                <label><h3>How to delete the recurrent sync</h3></label>
                                <label className="label"> If you want to stop the recurrent sync, you need to execute this script as above but instead of file path pass <p className="bold">-Mode Delete</p>.</label>
                                <label style={{ marginBottom: "5px" }}>Example:</label>
                                <div className="code-block">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr style={{ verticalAlign: "top" }}>
                                                <td>
                                                    <label className="white">C:\Users\Admin{'>'}</label> {" "}
                                                </td>
                                                <td>
                                                    <label className="yellow">Powershell</label> {" "}
                                                    <label className="grey">-File</label> {" "}
                                                    <label className="white">C:\Users\Admin\Downloads\{`${scriptName}`}</label> {" "}
                                                    <label className="grey">-Mode</label> {" "}
                                                    <label className="white"> Delete</label>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        </ul>
                    </div>
                </>
            }
            {
                customSection.display &&
                <>
                    <div className="row" >
                        <button type="button" className="collapsible" onClick={() => setCustomSection({ ...customSection, open: !customSection.open })}>
                            {getArrowIcon(customSection.open)}Custom recurrent import
                        </button>
                    </div>
                    <div className={`collapsible-content ${customSection.open ? "active" : ""}`} >
                        <label>Necessary variables:</label>
                        <div className="sync-variables">
                            <div className="variable-row">
                                <label htmlFor="endpoint" className="label">
                                    Endpoint:
                                </label>
                                <input className="long" disabled type="text" id="token-token-box" name="endpoint" value={endpointUrl ?? ""} />
                                <button id="token-copy-button" className="link-button" onClick={copyEndpointUrlToClipboard} >
                                    Copy
                                </button>
                            </div><div className="variable-row">
                                <label htmlFor="token" className="label">
                                    Token:
                                </label>
                                <input className="long" disabled type="text" id="token-token-box" name="token" value={token ?? ""} />
                                <button id="token-copy-button" className="link-button" onClick={copyTokenToClipboard} >
                                    Copy
                                </button>
                            </div><div className="variable-row">
                                <label htmlFor="syncId" className="label">
                                    Sync id:
                                </label>
                                <input className="long" disabled type="text" id="token-token-box" name="syncId" value={syncId ?? ""} />
                                <button id="token-copy-button" className="link-button" onClick={copySyncIdToClipboard} >
                                    Copy
                                </button>
                            </div>
                            <label>Please navigate to our community article for detailed instructions.</label>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
