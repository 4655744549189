import container from '../infrastructure';
import { FileHeaderMappingResponseModel } from '../models/file-header-mapping-response.model';
import { FileHeaderMappingModel } from '../models/file-header-mapping.model';
import { FileValidationModel } from '../models/file-validation.model';
import { SchemaHeader } from '../models/schema-header.model';
import { SchemaType } from '../models/enums/schema-type.enum';
import { importActions } from './import';
import { mainActions } from './main';

export const uploadFile = ({ file, schemaType }: { file: File, schemaType: SchemaType }) => {
  return async (dispatch: Function) => {
    try {
      dispatch(importActions.handleSetFileName(file.name));
      const response: FileValidationModel = await container.fileImportRepository.uploadFile({ file, schemaType })
      const serializedResponse = {
        fileHeaders: response.fileHeaders,
        errorMessages: response.validationResult.errorMessages,
        sessionId: response.sessionId,
        syncId: response.syncId
      }
      dispatch(importActions.handleFileUpload(serializedResponse));

    } catch (err) {
      console.error(`Error retrieving data from syncApi with message ${err}`);
    }
  };
};

export const saveFileMappings = ({ syncId, mappingsJson, syncName }: { syncId: string, mappingsJson: string, syncName: string }) => {
  return async (dispatch: Function) => {
    try {
      var mappingsDto = JSON.parse(mappingsJson);
      var mappings = Object.keys(mappingsDto)
        .map((key: string) => new FileHeaderMappingModel({ fileHeader: mappingsDto[key], schemaHeader: key }))
        .filter(m => !!m.fileHeader)

      const response: FileHeaderMappingResponseModel = await container.fileImportRepository.saveMappings({ syncId, mappings, syncName })

      if (response.validationResult.isSuccessful) {
        dispatch(importActions.handleSetShouldRedirect(response.validationResult.isSuccessful));
        dispatch(mainActions.handleShowNotification("Sync was successfully scheduled! Processing shall start soon."));
      }
      else {
        dispatch(importActions.handleSetFileErrors(response.validationResult.errorMessages));
      }

    } catch (err) {
      console.error(`Error retrieving data from syncApi with message ${err}`);
    }
  };
};

export const disableRedirect = () => {
  return async (dispatch: Function) => {
    dispatch(importActions.handleSetShouldRedirect(false));
  }
}

export const getSchemaMappings = ({ schemaType }: { schemaType: SchemaType }) => {
  return async (dispatch: Function) => {
    try {

      var mappings: SchemaHeader[] = [];
      if (!!schemaType) {
        mappings = await container.fileImportRepository.getSchemaHeaders({ schemaType })
      }

      dispatch(importActions.handleSetSchemaHeaders(mappings));
    } catch (err) {
      console.error(`Error retrieving headers for schema "${schemaType.name}" with message: ${err}`);
    }
  };
};

export const getSchema = ({ schemaType }: { schemaType: SchemaType }) => {
  return async (dispatch: Function) => {
    try {

      var mappings: SchemaHeader[] = [];
      if (!!schemaType) {
        mappings = await container.fileImportRepository.getSchemaHeaders({ schemaType })
      }

      dispatch(importActions.handleLoadSchema({id: schemaType.id, headers: mappings}));
    } catch (err) {
      console.error(`Error retrieving headers for schema "${schemaType.name}" with message: ${err}`);
    }
  };
};

export const loadRecurrentSchedulerScript = () => {
  return async (dispatch: Function) => {
        const script = await container.fileTemplatesRepository.getRecurrentScriptSchedulerTemplate();
        dispatch(importActions.handleGetRecurrentSchedulerScript(script));
  };
};

export const resetFileData = () => {
  return async (dispatch:  Function) => {
    dispatch(importActions.handleResetFile());
  }
}