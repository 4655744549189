import { ValidationResult } from "./shared/validation-result";
import { SyncStatus } from "./enums/sync-status.enum";

export class FileHeaderMappingResponseModel {
    public sessionId: string = null as unknown as string;
    public syncId: string = null as unknown as string;
    public validationResult: ValidationResult = null as unknown as ValidationResult;
    public syncStatus: SyncStatus = null as unknown as SyncStatus;

    public constructor({sessionId, syncId, validationResult, syncStatus}: {sessionId: string, syncId: string, validationResult: ValidationResult, syncStatus: SyncStatus}) {
        this.sessionId = sessionId;
        this.syncId = syncId;
        this.validationResult = validationResult;
        this.syncStatus = syncStatus;
    }
    public static fromJson(json: any): FileHeaderMappingResponseModel {
        var dto = JSON.parse(json);
        return new FileHeaderMappingResponseModel ({
            sessionId: dto['sessionId'],
            syncId: dto['syncId'],
            validationResult: dto['validationResult'],
            syncStatus: SyncStatus.GetById(parseInt(dto['syncStatus'])) as SyncStatus,
        })
    }
}