import { PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { AxiosInstance } from 'axios';
import { getSyncProxyControllerUrl } from '../../services/helper';
import { ScimLogRecord } from '../../models/scim-log-record.model';
import { HttpResponseModel } from '../../models/shared/http-response.model';


export type ScimLogsPaginatedDataSource = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
  webFormsToken: string;
}

export interface ScimHistoryDatasource {
  getScimLogsPaginated(
    { pageSize, pageNumber, sortingField, isAscending, webFormsToken }: ScimLogsPaginatedDataSource)
    : Promise<PaginatedResponseModel<ScimLogRecord>>;
  cancelResumeScimSync({enableProcessing, webFormsToken}: { enableProcessing: boolean, webFormsToken: string }): Promise<HttpResponseModel<string>>;
  retryScimSync({sessionId,webFormsToken}: {sessionId:string, webFormsToken: string }): Promise<HttpResponseModel<string>>;
}

export class ScimHistoryDataSourceImpl implements ScimHistoryDatasource {
  constructor(private client: AxiosInstance) {}

  async getScimLogsPaginated(
    { pageSize, pageNumber, sortingField, isAscending, webFormsToken }: ScimLogsPaginatedDataSource)
    : Promise<PaginatedResponseModel<ScimLogRecord>> {

    const query = `${getSyncProxyControllerUrl()}/scim-history/logs`;
    const data = {
      pageSize,
      pageNumber: pageNumber + 1,
      sortingField,
      isAscending
    };

    const response = await this.client.post<string>(query, data, {
      headers: { Authorization: 'Bearer ' + webFormsToken }
    });

    if (response.status === 200 || !!(response.data)) {
      return PaginatedResponseModel.fromJson<ScimLogRecord>(JSON.stringify(response.data), ScimLogRecord.fromJObj);
    }
    else {
      throw new Error(`Error getting site operations. Status code: ${response.status}`);
    }
  }

  async cancelResumeScimSync({enableProcessing, webFormsToken}: { enableProcessing: boolean, webFormsToken: string }): Promise<HttpResponseModel<string>> {
    const query = `${getSyncProxyControllerUrl()}/scim-history/access`;
    const data = {
      enableProcessing,
    }
    const response = await this.client.post<string>(query, data, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
    });

    return new HttpResponseModel<string> ({
      isSuccessful: response.status === 200,
      data: response.data
    });
  }

  async retryScimSync({sessionId, webFormsToken }: {sessionId:string, webFormsToken: string; }): Promise<HttpResponseModel<string>> {
    const query = `/sessions/${sessionId}/retry`;
    const response = await this.client.post<string>(query, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
    });

    return new HttpResponseModel<string> ({
      isSuccessful: response.status === 200,
      data: response.data
    });
  }
}
