import { createSlice } from '@reduxjs/toolkit';
import { SyncLogRecord } from '../models/sync-log-record.model';
import { SyncSessionInfo } from '../models/sync-session-info.model';

const initialSyncSessionsState: SyncSessionsState = {
  history: [],
  logs: [],
  logsTotalCount: 0,
  historyTotalCount: 0,
  historyPageCount: 0,
  logsPageCount: 0,
  sessionInfo: null,
  reloadHistoryTrigger: false,
};

const syncSessionsSlice = createSlice({
  name: 'sync-sessions',
  initialState: initialSyncSessionsState,
  reducers: {
    handleGetHistory(state, action) {
      state.history = action.payload;
    },
    handleGetLogs(state, action) {
      state.logs = action.payload;
    },
    handleGetHistoryPaginated(state, action) {
      state.history = action.payload.items;
      state.historyTotalCount = action.payload.totalItemsCount;
      state.historyPageCount = Math.ceil(action.payload.totalItemsCount/action.payload.pageSize);
    },
    handleGetLogsPaginated(state, action) {
      state.logs = action.payload.items;
      state.logsTotalCount = action.payload.totalItemsCount;
      state.logsPageCount = Math.ceil(action.payload.totalItemsCount/action.payload.pageSize);
    },
    handleGetSessionInfo(state, action) {
      state.sessionInfo = action.payload;
    },
    handleReloadHistoryTrigger(state) {
      state.reloadHistoryTrigger = !state.reloadHistoryTrigger;
    }
  },
});

export interface SyncSessionsState {
  history: SyncSessionInfo[];
  logs: SyncLogRecord[];
  historyTotalCount: number;
  historyPageCount: number;
  logsTotalCount: number;
  logsPageCount: number;
  sessionInfo: SyncSessionInfo|null;
  reloadHistoryTrigger: boolean;
}

export const syncSessionsActions = syncSessionsSlice.actions;

export default syncSessionsSlice.reducer;
