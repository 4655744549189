import * as React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './modal-wrapper.component.scss'

export const MODAL_STYLES = {
    content: {
      maxWidth: '400px',
      padding: '20px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      border: 0,
      boxShadow: '0 0 0 1px rgba(9, 30, 66, 0.08)'
    },
  };
  

interface OnCloseFunc {
  (): void
}

export interface ModalWrapperInterface {
  children?: JSX.Element|JSX.Element[],
  onClose: OnCloseFunc,
  isOpen: boolean
}

const ModalWrapper = (props: ModalWrapperInterface) => {
  const { children, isOpen, onClose } = props;
  //useEffect(() => Modal.setAppElement('#output'), []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={MODAL_STYLES}
      contentLabel="Modal"
      ariaHideApp={false}
      overlayClassName="modal--overlay"
    >
      <div onClick={onClose} role="button" tabIndex={1} className="modal--closeButton">
        <FontAwesomeIcon icon={faTimes} />
      </div>
      {children}
    </Modal>
  );
};

export default ModalWrapper;
