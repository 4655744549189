import { faCaretDown, faCaretUp, faCheckCircle, faClock, faExclamationTriangle, faMagnifyingGlass, faSpinner, faStopCircle, faUserAlt, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import InfoTooltip from './info-tooltip.component';
import { Table, TableProps } from './table.component';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import container from '../infrastructure';
import { ModalProps } from '../models/modal-props.model';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from '../store/main';
import { ScimEntityType } from '../models/scim-entity-type.model';
import { EntityFailureRecordAuditModel, EntityFailureStatus } from '../models/entity-failure.record.model';
import ModalWrapper from './modal-wrapper-component';
import './entity-retries.scss';
import { Redirect } from 'react-router-dom';

interface EventInterface {
  target: any
}

const formatDate = (date: null | number) => {
  return <label>{date ? new Date(date).toLocaleString() : '—'} </label>;
};

const AuditTrailsComponent = ({ auditTrails, createdAt }: { auditTrails: EntityFailureRecordAuditModel[], createdAt: number }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span> {formatDate(createdAt)}</span>
        {auditTrails.length ? <FontAwesomeIcon onClick={() => setIsExpanded((value) => !value)} icon={isExpanded ? faCaretUp : faCaretDown} style={{ color: 'grey', marginRight: '5px' }} /> : null}
      </div>

      {isExpanded ? (
        <div className="mt-10">
          <div>Audit Trails related to this entity </div>
          {auditTrails.map((event: any) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await navigator.clipboard.writeText(event.auditId);

                toast.info('Audit Id has been copied to your clipboard', {
                  position: toast.POSITION.BOTTOM_CENTER,
                  style: { fontSize: 14, width: '380px' },
                  autoClose: 3000,
                });
              }}
            >
              {formatDate(event.timeStamp)}
              <InfoTooltip title="AuditId" subtitle={event.auditId} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export const EntityRetries = () => {
  const columns: Column[] = useMemo(() => tableColumns, []);
  const dispatch = useDispatch();
  const routePrefix = useSelector((state: any) => state.main.routePrefix);
  const [redirectToAudit, setRedirectToAudit] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [findEntityType, setFindEntityType] = useState<ScimEntityType | undefined>();
  const [findEntityName, setFindEntityName] = useState<string>("");
  const [findEntityErrors, setFindEntityErrors] = useState<string[]>([]);
  const [findEntityId, setFindEntityId] = useState<string|null|undefined>(undefined);

  const handleSyncNameChange = (event: EventInterface) => {
    setFindEntityId(undefined);
    setFindEntityName(event.target.value);
  }
  const handleSelectChange = (e: EventInterface) => {
    setFindEntityId(undefined);
    const { value } = e.target;
    let parsedValue = parseInt(value);
    if (Number.isNaN(parsedValue))
    {
      parsedValue = undefined as unknown as number;
    }
    //@ts-ignore
    setFindEntityType(parsedValue);
  };

  const handleFixEntity = async () => {
    const entityIdValue = `${ScimEntityType[findEntityType as unknown as ScimEntityType].toString().toLowerCase()}#${findEntityId}`;
    await container.entityRetryRepository.initiateRetry(entityIdValue)
    .then(res => 
      setRedirectToAudit(true))
    .catch(err => 
      setFindEntityErrors([...err.data]));
  }

  const searchEntity = () => {
    setFindEntityId("");

    let validationErrors = [];
    if (findEntityType === undefined) {
      validationErrors.push("Entity type is not chosen.")
    }
    if (findEntityName?.length === 0) {
      validationErrors.push("Entity name is not chosen");
    }

    if (validationErrors.length > 0) {
      setFindEntityErrors(validationErrors);
      setFindEntityId("");
    }
    else {
      container.entityRetryRepository.checkIfEntityExists({
        entityName: findEntityName, 
        entityType: findEntityType as unknown as ScimEntityType})
      .then(res => 
        {
          setFindEntityId(res);
          if (!res)
          {
            const message = 
              findEntityType === ScimEntityType.User
              ? "User is not found"
              : "Group is not found";
            setFindEntityErrors([message]);
          }
          else {
            setFindEntityErrors([]);
          }
        })
      .catch(err => {
        setFindEntityErrors([...err.data]);
        setFindEntityId("");
      });
    }
  };

  const [gridData, setGridData] = useState<any>({
    totalItemsCount: 1,
    pageIndex: 1,
    pageSize: 25,
    items: [],
  });

  const requestDataCallBack = useCallback(
    async (arg: any) => {
      const result = await container.entityRetryRepository.getEntityRetriesPaginated({
        isAscending: arg.isAscending,
        pageNumber: arg.pageNumber,
        pageSize: arg.pageSize,
        sortingField: arg.sortingField,
      });

      setGridData({
        ...result,
        items: result.items.map((item) => {
          (item as any).onRetryClick = () => {

            const entityTypeLabel = (item.entityType === ScimEntityType.User ? 'User' : 'Group');

            const modalProps: ModalProps = {
              isModalOpen: true,
              modalHeader: 'Retry Entity',
              modalText: [`This will try to resolve any problems and repush the entity, overriding any changes made on site.`, `Are you sure you want to proceed?`, `Entity: ${entityTypeLabel} ${item.affectedEntityName}`],
              modalPrimaryButtonText: 'Retry',
              modalSecondaryButtonEnabled: true,
              modalSecondaryButtonText: 'Cancel',
              handleModalSubmit: async () => {
                await container.entityRetryRepository.initiateRetry(item.affectedEntityId);

                requestDataCallBack(arg);

                dispatch(mainActions.handleHideModal());
              },
              handleModalClose: () => dispatch(mainActions.handleHideModal()),
              handleModalSecondary: () => dispatch(mainActions.handleHideModal()),
              modalErrorsText: '',
              primaryButtonClasses: 'delete-button',
            };

            dispatch(mainActions.handleSetModalProps(modalProps));
          };

          return item;
        }),
      });
    },
    [dispatch]
  );

  const dataPayload = useMemo(() => {
    return {};
  }, []);

  const tableProps: TableProps = {
    columns: columns,
    data: gridData.items,
    tableId: 'entityRetries-table',
    requestDataCallBack: requestDataCallBack, //change to typed lambda?
    totalCount: gridData.totalItemsCount,
    dataPayload: dataPayload,
    pageCounter: Math.ceil(gridData.totalItemsCount / gridData.pageSize),
    showRefreshButton: true,
    requestDataWithoutDispatch: true,
    emptyTableText: "All entities are pushed successfully!"
  };

  const handleModalClose = () => {
    setIsOpenModal(false);
  };

  const getEntityExistsIcon = () => {
    if ((findEntityId?.length ?? 0) > 0) {
      return <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} />
    } else if (findEntityId === "") {
      return <FontAwesomeIcon icon={faStopCircle} style={{ color: "red" }} />
    } else if (findEntityId === null) {
      return <FontAwesomeIcon icon={faClock} style={{ color: "lightgrey" }} />
    }
    return <></>;
  }

  return (
    <div className="entity-retries-page">
      {redirectToAudit &&
        <Redirect to={routePrefix}/>
      }
      <div className="entity-retries">
        <div>
          <button type="button" className="action-button buttonPrimary fix-entity-button" style={{width:"10em", display: "none"}} onClick={(e) => setIsOpenModal(true)}>Fix another entity</button>
        </div>
        <Table {...tableProps} />
      </div>
      <ToastContainer />
      <ModalWrapper isOpen={isOpenModal} onClose={handleModalClose}>
        <h2 className="find-entity-modal--title">Fix another entity</h2>
        { (findEntityErrors?.length ?? 0) > 0 ?
          <>
            <p className="find-entity-modal--text find-entity-modal--errors">{
              findEntityErrors.map((e, i) => <p key={i}>{e}</p>)
            }</p>
          </>
          : <></>
        }
        
        <p className="find-entity-modal--text">You can restore state of the entity to match state in the external provider (like Okta or Azure)</p>
        <div>
          <p className="find-entity-modal--text">Choose entity type:</p>
          <select id="find-entity-type" style={{width:"5em"}} onChange={handleSelectChange}>
            <option value="" />
            <option key={ScimEntityType.User} value={ScimEntityType.User}>User</option>
            <option key={ScimEntityType.Group} value={ScimEntityType.Group}>Group</option>
          </select>
        </div>
        <div style={{display:"inline-block"}}>
          <p className="find-entity-modal--text">Enter {findEntityType === ScimEntityType.User ? "username" : "entity name"} and press "Search":</p>
          <input type="text" id="find-entity-name" name="find-entity-name" style={{width:"20em", marginRight:"5px"}} value={findEntityName as unknown as string} onChange={handleSyncNameChange} />
          <button style={{height:"32px", width: "32px"}} type="button" onClick={searchEntity}><FontAwesomeIcon icon={faMagnifyingGlass}/></button>
          <p style={{display:"inline-block", marginLeft: "5px"}}>{getEntityExistsIcon()}</p>
        </div>
        <div className="find-entity-modal--buttons">
          <button disabled={!findEntityId} className="buttonPrimary" onClick={handleFixEntity}>Fix</button>
          <button className="buttonSecondary" onClick={handleModalClose}>Close</button>
        </div>
      </ModalWrapper>
    </div>
  );
};

export const tableColumns = [
  {
    Header: 'First Failure At',
    accessor: (row: any) => {
      return <AuditTrailsComponent createdAt={row.createdAt} auditTrails={row.auditTrails} />;
    },
    width: "3em",
    disableSortBy: true,
  },
  {
    Header: 'Entity',
    accessor: (row: any) => {
      return <>
        <FontAwesomeIcon icon={row.entityType === ScimEntityType.User ? faUserAlt : faUserGroup} />
        &nbsp;
        {row.affectedEntityName}
      </>;
    },
    width: "4em",
    disableSortBy: true,
  },
  {
    Header: 'Status',
    accessor: (row: any) => {
      if (row.status === EntityFailureStatus.Failed)
        return (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#D13C3B', marginRight: '5px' }} /> Failed
          </>
        );

      return (
        <>
          <FontAwesomeIcon icon={faSpinner} style={{ color: 'green', marginRight: '5px' }} /> Retry In Progress{' '}
        </>
      );
    },
    width: "1em",
    disableSortBy: true,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
    disableSortBy: true,
    width: "8em",
  },
  {
    Header: 'Actions',
    accessor: (row: any) => {
      if (row.canRetry)
        return (
          <button type="button" className="buttonPrimary" onClick={row.onRetryClick}>
            Retry
          </button>
        );

      return null;
    },
    width: "2em",
    disableSortBy: true,
  },
];
