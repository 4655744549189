import { faStopCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle, faClock, faEllipsisH, faExclamationTriangle, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Column } from 'react-table';
import { SchemaType } from "../models/enums/schema-type.enum";
import { SyncStatus } from "../models/enums/sync-status.enum";
import { SyncSessionCount, SyncSessionInfo } from "../models/sync-session-info.model";
import { showModalCancelScimSync, showModalCancelSession, showModalRetryScimSync } from "../store/main-actions";
import { cancelResumeScimSync, getScimRetryTreatment } from '../store/scim-history-actions';
import { getHistoryPaginated } from '../store/sync-sessions-actions';
import InfoTooltip from "./info-tooltip.component";
import './sync-sessions-history.scss';
import { Table, TableProps } from "./table.component";

export const SyncSessionsHistory = () => {
    const routePrefix = useSelector((state: any) => state.main.routePrefix);
    const dispatch = useDispatch();

    const syncHistory: SyncSessionInfo[] = useSelector((state: any) => state.syncSessions.history);
    const totalCount: number = useSelector((state: any) => state.syncSessions.historyTotalCount);
    const pageCount: number = useSelector((state: any) => state.syncSessions.historyPageCount);
    const reloadDataTrigger: boolean = useSelector((state: any) => state.syncSessions.reloadHistoryTrigger);

    const allowRetryScim: boolean = useSelector((state: any) => state.scimHistory.retryIsEnabled);

    useEffect(() => {
        dispatch(getScimRetryTreatment())
    }, [dispatch]);
 
    const columns: Column[] = useMemo(() => tableColumns, []);
    const getActionsColumn = useMemo(() => (sessionId: string, ss: SyncStatus, routePrefix: string, type: SchemaType) => {
        const isScim = type === SchemaType.SCIM;
        let scimStatus: SyncStatus|null  = null;
        if (isScim) {
            scimStatus = ss;
        }
        return <div className="actions-column">
            <div>
                {
                    ((ss === SyncStatus.Processing || ss === SyncStatus.WaitingForOperations || ss === SyncStatus.Queued) && !isScim) &&
                    <button className="link-button red" onClick={(e) => { e.preventDefault(); dispatch(showModalCancelSession(sessionId)); }}>Cancel</button>
                }

                {
                  (ss !== SyncStatus.Failed && isScim) &&
                  <button
                    className={ss !== SyncStatus.Canceled ? 'link-button red' : 'link-button default'}
                    onClick={handleScimAccessButtonClick}
                    data-testid="scim-btn">
                      {ss !== SyncStatus.Canceled ? "Cancel" : "Resume"}
                  </button>
                }
                {
                    (ss === SyncStatus.Failed && isScim && allowRetryScim) &&
                    <button className="link-button default"
                    onClick={handleScimRetryButtonClick}
                    data-testid="scim-btn">
                        Retry
                    </button>
                }
            </div>
            <div>
                {
                    !isScim && <Link to={`${routePrefix}/session/${sessionId}/logs`}>View Logs</Link>
                }
                {
                    isScim && <Link to={`${routePrefix}/scim-logs`}>View Logs</Link>
                }
            </div>
        </div>

        function handleScimAccessButtonClick(event: React.MouseEvent<HTMLButtonElement>): void {
            event.preventDefault();

            //isn't displayed for "Failed" status anyway
            if (scimStatus !== SyncStatus.Canceled) {
                dispatch(showModalCancelScimSync());
            } else {
                // Reenable canceled SCIM without confirmation modal
                dispatch(cancelResumeScimSync(true));
            }
        }

        function handleScimRetryButtonClick(event: React.MouseEvent<HTMLButtonElement>): void {
            event.preventDefault();
            dispatch(showModalRetryScimSync(sessionId));
        }
    },[dispatch, allowRetryScim]);

    const getNameLabel = useMemo(() => (name: string|null, sessionId: string, typeId: number) => {
        const isSCIM = typeId === SchemaType.SCIM.id;

        return (<div>
            { !isSCIM &&
                <Link to={`${routePrefix}/session/${sessionId}/info`}>
                    <div style={{cursor:"default"}}>
                        <label className="truncate" style={{cursor:"pointer", verticalAlign:"middle"}}>
                            {name ?? '—'}
                        </label>
                    </div>
                </Link>
            }
            { isSCIM &&
                <div style={{cursor:"default"}}>
                    <label className="truncate" style={{verticalAlign:"middle"}}>
                        {name ?? '—'}
                    </label>
                </div>
            }
        </div>
    )}, [routePrefix]);

    const data = useMemo(() => syncHistory?.map((sh: SyncSessionInfo) => {
        return {
            ...sh,
            name: getNameLabel(sh.name, sh.id, sh.type?.id),
            runDate: getRunDateLabel(sh.type, sh.creationDate, sh.lastModifiedDate),
            status: getStatusLabel(sh.status),
            synced: getSyncedComponent(sh.syncedCount, sh.type, sh.status, sh.cancelDate),
            type: sh.type?.name ?? '—',
            userName: <label className="truncate">{sh.userName ?? '—'}</label>,
            actions: getActionsColumn(sh.id, sh.status, routePrefix, sh.type),
        }
    }) ?? [], [syncHistory, routePrefix, getActionsColumn, getNameLabel]);

    const requestDataCallBack = useCallback((arg: any) => getHistoryPaginated(arg), []);
    const dataPayload = useMemo(() => { return {} }, []);

    const tableProps: TableProps = {
        columns: columns,
        data: data,
        tableId: "syncSessionsHistory-table",
        requestDataCallBack: requestDataCallBack, //change to typed lambda?
        totalCount: totalCount,
        pageCounter: pageCount,
        dataPayload: dataPayload,
        refreshDataTrigger: reloadDataTrigger,
        showRefreshButton: false
    };

    return (
        <>
            <div className="syncSessionsHistory">
                <Table {...tableProps} />
            </div>
        </>
    )
}

const getStatusLabel = (st: SyncStatus) => {
    let icon = <></>;
    switch (st) {
        case SyncStatus.Configuring: {
            icon = <FontAwesomeIcon icon={faEllipsisH} style={{ color: "grey", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Queued: {
            icon = <FontAwesomeIcon icon={faClock} style={{ color: "grey", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Processing: {
            icon = <FontAwesomeIcon icon={faSpinner} style={{ color: "grey", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.WaitingForOperations: {
            icon = <FontAwesomeIcon icon={faSpinner} style={{ color: "grey", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Failed: {
            icon = <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: "#D13C3B", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Completed: {
            icon = <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.CompletedWithErrors: {
            icon = <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#DAA520", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Canceled: {
            icon = <FontAwesomeIcon icon={faStopCircle} style={{ color: "rgb(178, 23, 23)", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.InSync: {
            icon = <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green", marginRight: "5px" }} />
            break;
        }
        case SyncStatus.Pending: {
            icon = <FontAwesomeIcon icon={faCheckCircle} style={{ color: "rgba(0, 0, 0, 0)", marginRight: "5px" }} />
            break;
        }
    }
    return <label>{icon} {st.name ?? '—'}</label>
}

const getSyncedComponent = (sc: SyncSessionCount, st: SchemaType, ss: SyncStatus, cd: Date|null) => {
    if (st === SchemaType.SCIM) {
        return <label>—</label>;
    }

    if (SyncStatus.GetCompleted().includes(ss)) {
        const countLabel = (!!sc && sc.syncCount !== 0) ? `${sc.syncCount}` : '—';
        let countSuffixLabel = '';
        //If session was canceled less than a minute ago, we should display tooltip
        const shouldShowCancelTip = ss === SyncStatus.Canceled && Date.now() - (cd as unknown as Date).getTime() < 60000;

        if (sc.syncCount > 0)
        {
            if (sc.syncCount === 1) {
                countSuffixLabel = " record";
            }
            else {
                countSuffixLabel = " records";
            }
            if (shouldShowCancelTip) {
                countSuffixLabel += "*";
            }
        }

        let labelSuffix = shouldShowCancelTip ? <InfoTooltip title="The session was canceled recently" subtitle="There still may be operations in progress.
        Count may be updated soon." /> : '';

        return <label style={{verticalAlign:"middle"}}>{countLabel} {countSuffixLabel} {labelSuffix}</label>
    }
    else {
        return <label>Processing...</label>;
    }
}

const getRunDateLabel = (type: SchemaType, creationDate: Date, lastModifiedDate: Date|null) => {
    let dateToDisplay = type === SchemaType.SCIM
        ? lastModifiedDate
        : creationDate;
    return <label>{dateToDisplay?.toLocaleString() ?? '—'} </label>
}

export const tableColumns = [
    {
        Header: 'Status',
        accessor: 'status',
        width: "2em",
        defaultCanSort: true
    },
    {
        Header: 'Sync Name',
        accessor: 'name',
        width: "4em",
        defaultCanSort: true
    },
    {
        Header: 'Synced',
        accessor: 'synced',
        width: "1em",
        defaultCanSort: false,
        disableSortBy: true
    },
    {
        width: "2em",
        Header: 'Username',
        accessor: 'userName',
        defaultCanSort: true
    },
    {
        width: "2em",
        Header: 'Type',
        accessor: 'type',
        defaultCanSort: true
    },
    {
        width: "3em",
        Header: 'Last Run Date',
        accessor: 'runDate',
        defaultCanSort: true
    },
    {
        width: "2em",
        Header: 'Actions',
        accessor: 'actions',
        defaultCanSort: false,
        disableSortBy: true
    },
];
