import { SchemaType } from "../../models/enums/schema-type.enum";
import { FileTemplatesDataSource } from "../datasources/file-templates.datasource";

export interface FileTemplatesRepository {
    getFileTemplate({ schema }: { schema: SchemaType }): string;
    getRecurrentScriptSchedulerTemplate(): string;
}

export class FileTemplatesRepositoryImpl implements FileTemplatesRepository {
    constructor(private dataSource: FileTemplatesDataSource) { }
    getFileTemplate({ schema }: { schema: SchemaType }): string {
        switch (schema) {
            case SchemaType.ImportUsers:
                return this.dataSource.getImportUsersTemplate()
            case SchemaType.ImportGroups:
                return this.dataSource.getImportGroupsTemplate()
            case SchemaType.UpdateUsername:
                return this.dataSource.getUpdateUsernamesTemplate()
            case SchemaType.UpdateGroupName:
                return this.dataSource.getUpdateGroupnamesTemplate()
            default:
                return null as unknown as string;
        }
    }

    getRecurrentScriptSchedulerTemplate(): string {
        return this.dataSource.getRecurrentScriptSchedulerTemplate();
    }
}