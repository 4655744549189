import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, resetState } from '../store/token-actions';
import './token-form.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import Tooltip from './tooltip.component';
import { getSyncProxyControllerUrl } from '../services/helper';

export const TokenFormComponent = ({ showOnlyInputs, showTenantUrl }: { showOnlyInputs: boolean; showTenantUrl: boolean }) => {
  const dispatch = useDispatch();
  const token: string = useSelector((state: any) => state.token.token);
  const isLoading: boolean = useSelector((state: any) => state.token.isLoading);
  const errors: string = useSelector((state: any) => state.token.errors);
  const [userName, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [tokenLabel, setTokenLabel] = useState('');

  const [copyToken, setCopyToken] = useState('Copy Token');
  const [copyTenantUrl, setCopyTenantUrl] = useState('Copy URL');

  //consider moving to separate "service"
  //@ts-ignore
  const siteKey: string = useMemo(() => window?.siteProfile?.siteKey ?? "sync", []);
  const tenantUrl = useMemo(() => `${getSyncProxyControllerUrl()}/scim/`, []);


  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
  }

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
  }

  const sendTokenClick = (e: any) => {
    e.preventDefault();
    dispatch(getToken({ userName, password, siteKey }));
  }
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch])

  const copyTokenToClipboard = async (e: any) => {
    e.preventDefault();
    await navigator.clipboard.writeText(token);
    setCopyToken('Copied');
    setTimeout(() => { setCopyToken('Copy Token')}, 2000);
  }

  useEffect(() => {
    if (isLoading) {
      setTokenLabel("Loading...");
    }
    else {
      setTokenLabel(token)
    }
  }, [isLoading, token])

  const buttonEnabled = useMemo(() => userName?.length > 0 && password?.length > 0, [userName, password]);

  const buttonText = useMemo(() => {
    return showOnlyInputs
      ? "Confirm"
      : "Generate Token"
  }, [showOnlyInputs])

  const isDisabled = isLoading || token === null || token === '';

  async function copyTenantUrlToClipboard(e: any) {
    e.preventDefault();
    await navigator.clipboard.writeText(tenantUrl);
    setCopyTenantUrl('Copied');
    setTimeout(() => { setCopyTenantUrl('Copy URL')}, 2000);
  }

  return (
    <div className="token-form">
      <div id="token-errors" className={`${(errors !== null && errors.length > 0) ? "errors" : null}`}>
        {errors}
      </div>
      {showTenantUrl && <div className="token-row">
        <label htmlFor="tenant-url" className="tenant-label">
          Tenant URL:
        </label>
        <input className="long" disabled type="text" id="tenant-url" name="tenant-url" value={tenantUrl} />
        <button className="link-button copy" id="url-copy-button" onClick={copyTenantUrlToClipboard}>
          <Tooltip title={copyTenantUrl} element={<FontAwesomeIcon
            className="copy-icon"
            icon={faCopy}
            color="#5D5D5D" size="2x"
          />} />
        </button>
      </div>}
      <div className="token-row">
        <label htmlFor="userName" className="label">
          Username:
        </label>
        <input type="text" id="token-input-userName" name="userName" onChange={handleUsernameChange} value={userName} />
      </div>
      <div className="token-row">
        <label htmlFor="password" className="label">
          Password:
        </label>
        <input type="password" id="token-input-password" name="password" onChange={handlePasswordChange} value={password} />
      </div>
      {
        !showOnlyInputs &&
        <>
          <div className="token-row">
            <label htmlFor="token" className="label">
              Token:
            </label>
            <input className="long" disabled type="text" id="token-token-box" name="token" value={tokenLabel ?? ""} />
            <button
              id="token-copy-button"
              className="link-button copy"
              disabled={isDisabled}
              onClick={copyTokenToClipboard}
            >
              <Tooltip title={copyToken} element={<FontAwesomeIcon
                className={isDisabled ? 'icon-disabled' : 'copy-icon'}
                icon={faCopy}
                color={isDisabled ? '#5D5D5D' : '#000'} size="2x"
              />} />
            </button>
          </div>
        </>
      }
      <div className="token-row">
        <label />
        <button disabled={!buttonEnabled} className="action-button" onClick={sendTokenClick}>{buttonText}</button>
      </div>
    </div>
  )
}
