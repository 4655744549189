import { SchemaType } from "./enums/schema-type.enum";
import { SyncStatus } from "./enums/sync-status.enum";

export class SyncSessionInfo {
    public id: string = null as unknown as string;

    public syncId: string = null as unknown as string;

    public status: SyncStatus = null as unknown as SyncStatus;

    public name: string|null = null;

    public syncedCount: SyncSessionCount = null as unknown as SyncSessionCount;

    public userName: string|null = null;

    public type: SchemaType = null as unknown as SchemaType;

    public creationDate: Date = null as unknown as Date;

    public lastModifiedDate: Date|null = null;

    public cancelDate: Date|null = null;

    public mappings: string|null = null;

    public constructor({id, syncId, status, name, syncedCount, userName, type, creationDate, lastModifiedDate, cancelDate, mappings}:
        {id: string, syncId: string, status: SyncStatus, name: string, syncedCount: SyncSessionCount, userName: string|null, type: SchemaType, creationDate: Date, lastModifiedDate: Date|null, cancelDate: Date|null, mappings: string|null}) {
        this.status = status;
        this.name = name;
        this.syncedCount = syncedCount;
        this.userName = userName;
        this.type = type;
        this.creationDate = creationDate;
        this.lastModifiedDate = lastModifiedDate;
        this.cancelDate = cancelDate;
        this.id = id;
        this.mappings = mappings;
        this.syncId = syncId;
    }

    public static fromJson(json: any): SyncSessionInfo {
        var dto = JSON.parse(json);
        return this.fromJObj(dto);
    }

    public static fromJObj(dto: any): SyncSessionInfo {
        var syncedCount = new SyncSessionCount({
            totalCount: dto['totalOperationsCount'],
            successfulCount: dto['successfulOperationsCount'],
            failedCount: dto['failedOperationsCount'],
            syncCount: dto['syncCount']
        });

        return new SyncSessionInfo ({
            id: dto['id'],
            syncId: dto['syncId'],
            name: dto['name'],
            syncedCount: syncedCount,
            userName: dto['lastModifiedBy'],
            creationDate: new Date(dto['creationDate']),
            lastModifiedDate: new Date(dto['lastModifiedDate']),
            cancelDate: new Date(dto['cancelationDate']),
            status: SyncStatus.GetById(parseInt(dto["status"])) as SyncStatus,
            type: SchemaType.GetById(parseInt(dto["type"])) as SchemaType,
            mappings: dto['mappings'],
        })
    }
}

export class SyncSessionCount {
    public totalCount: number = null as unknown as number;
    public successfulCount: number = null as unknown as number;
    public failedCount: number = null as unknown as number;
    public syncCount: number = null as unknown as number;

    public constructor({totalCount, successfulCount, failedCount, syncCount}: {totalCount: number, successfulCount: number, failedCount: number, syncCount: number}) {
        this.totalCount = totalCount;
        this.successfulCount = successfulCount;
        this.failedCount = failedCount;
        this.syncCount = syncCount;
    }
}
