import { faArrowDown, faArrowUp, faCheckCircle, faExclamationTriangle, faSpinner, faUserAlt, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';
import { Column } from 'react-table';
import { Table, TableProps } from './table.component';
import { AuditTrailProjectedEventModel, AuditTrailProjectedEventStatus, AuditTrailRecord, AuditTrailRecordStatus, AuditTrailSource, ScimActionType } from '../models/audit-trail-record.model';
import InfoTooltip, { Tooltip } from './info-tooltip.component';
import { ScimEntityType } from '../models/scim-entity-type.model';
import { useDispatch, useSelector } from 'react-redux';
import { getAuditTrailPaginated } from '../store/audit-actions';
import { Link } from 'react-router-dom';
import './audit-trail.scss';

const formatDate = (date: string | number | null) => {

  if (!date)
    return null;

  if (typeof date == "string")
    date = Number.parseInt(date);

  return <label>{date ? new Date(date).toLocaleString() : '—'} </label>;
};

const SuccessLabel = () =>
  <>
    <FontAwesomeIcon icon={faCheckCircle} style={{ color: 'green', marginRight: '5px' }} />Success{' '}
  </>;

const ProcessingLabel = () =>
  <>
    <FontAwesomeIcon icon={faSpinner} style={{ color: 'green', marginRight: '5px' }} />Processing{' '}
  </>;

const QueuedLabel = () =>
  <>
    <FontAwesomeIcon icon={faSpinner} style={{ color: 'green', marginRight: '5px' }} />Queued{' '}
  </>;

const FailedLabel = () =>
  <>
    <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#D13C3B', marginRight: '5px' }} />Failed
  </>;

const RelatedEventsComponent = ({ events }: { events: AuditTrailProjectedEventModel[] | null }) => {
  if (events == null) events = [];

  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div className="flex flex-col justify-start">
      <div className="flex justify-between" onClick={() => setIsExpanded((value) => !value)}>
        <span>
          {events.length} related event{events.length !== 1 ? 's' : ''}
        </span>
        {events.length ? <FontAwesomeIcon icon={isExpanded ? faArrowUp : faArrowDown} style={{ color: 'grey', marginRight: '5px' }} /> : null}
      </div>

      {(isExpanded && events?.length > 0) && (
        <div className="mt-10">
          {events.map((event: AuditTrailProjectedEventModel, index: number) => {
            let status: JSX.Element | null = null;

            switch (event.status) {
              case AuditTrailProjectedEventStatus.Failed:
                status = <FailedLabel />;
                break;

              case AuditTrailProjectedEventStatus.Success:
                status = <SuccessLabel />;
                break;

              case AuditTrailProjectedEventStatus.Processing:
                status = <ProcessingLabel />;
                break;
            }

            return (
              <div className="event-details flex flex-col" key={`${event.timeStamp}-${index}`}>
                <div>
                  <strong>Event {index + 1} - {event.eventType}</strong>
                </div>
                <div className="flex">
                  <div className="event-details-row-key">Status:</div>
                  <div className="event-details-row-value">
                    {status}

                    {event.status === AuditTrailProjectedEventStatus.Failed
                      ? <InfoTooltip title="Error details" subtitle={event.ccmpHttpRequestErrorMessages?.join(".") || event.ccmpHttpRequestErrorCodes?.join(",") || event.userSyncErrorMessage || ""} />
                      : null}
                  </div>
                </div>
                <div className="flex">
                  <div className="event-details-row-key">Started At:</div>
                  <div className="event-details-row-value">{formatDate(event.processingStartedAt)}</div>
                </div>
                <div className="flex">
                  <div className="event-details-row-key">Ended At:</div>
                  <div className="event-details-row-value">{formatDate(event.processingEndedAt)}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

interface State {
  totalItemsCount: number;
  pageIndex: number;
  pageSize: number;
  items: AuditTrailRecord[];
}

export const AuditTrail = () => {
  const routePrefix = useSelector((state: any) => state.main.routePrefix);
  const columns: (Column & { hidden?: boolean })[] = useMemo(() => tableColumns, []);
  const dispatch = useDispatch();

  /* eslint-disable @typescript-eslint/no-unused-vars */
  const { error, pageSize, pageIndex, totalItemsCount, items }: State & { error: string } = useSelector((state: any) => state.audit);

  const requestDataCallBack = useCallback(async (arg: any) => {
    dispatch(getAuditTrailPaginated({
      isAscending: arg.isAscending,
      pageNumber: arg.pageNumber,
      pageSize: arg.pageSize,
      sortingField: arg.sortingField,
      filters: arg.filters
    }));
  }, [dispatch]);

  const dataPayload = useMemo(() => {
    return {};
  }, []);

  const hiddenColumns: string[] = useMemo(() => columns.filter(c => c.hidden).map(c => c.id as string), [columns]);
  const formattedItems = useMemo(() => items.map(i => {
    return {
      ...i,
      createdAt: formatDate(i.createdAt)
    };
  }), [items])

  const tableProps: TableProps = useMemo(() => {
    return {
      columns: columns,
      data: formattedItems ?? [],
      hiddenColumns,
      tableId: 'auditTrail-table',
      requestDataCallBack: requestDataCallBack,
      totalCount: totalItemsCount ?? 0,
      dataPayload: dataPayload,
      pageCounter: Math.ceil((totalItemsCount ?? 0) / (pageSize ?? 1)),
      showRefreshButton: true,
      requestDataWithoutDispatch: true,
    }
  }, [columns, formattedItems, totalItemsCount, dataPayload, hiddenColumns, pageSize, requestDataCallBack]);

  return (
    <div className="auditTrail-wrapper">
      {!!formattedItems.find(i => i.status === AuditTrailRecordStatus.Failed) &&
        <div className="auditTrail-note">
          <p><b style={{marginRight:"5px"}}>Note:</b> To check if there are any entities out of the sync please visit the tab
          <Link className="linkButton" to={`${routePrefix}/entity-retries`}>Provisioning Failures</Link>.</p>
          <p>Requests will remain failed for history logs purposes.</p>
        </div>
      }
      <div className="auditTrail">
        <Table {...tableProps} />
      </div>
    </div>
  );
};

export const tableColumns = [
  {
    Header: 'Received At',
    accessor: "createdAt",
    defaultCanSort: true,
    width: "10em",
  },
  {
    Header: 'Entity Name',
    width: 140,
    disableSortBy: true,
    accessor: (row: any) => {
      return <>
        <FontAwesomeIcon icon={row.scimEntityType === ScimEntityType.User ? faUserAlt : faUserGroup} />
        &nbsp;
        {row.affectedEntityName || "Unknown"}
      </>;
    }
  },
  {
    Header: 'AuditId',
    id: "AuditId",
    accessor: (row: any) => <div className='text-ellipsis' > {row.auditId}</div >,
    disableSortBy: true,
    width: 80,
    Filter: ({ column }: any) => {
      return <input type="text" onChange={(event) => column.setFilter(event.target.value)} style={{ width: '100%' }} value={column.filterValue || ""} />;
    },
    hidden: true
  },
  {
    Header: 'Status',
    id: "Status",
    width: 80,
    accessor: (row: any) => {
      if (row.status === AuditTrailRecordStatus.Failed) {
        if (row.prevalidationErrorMessage) {
          return <><Tooltip
            title='Reason'
            subtitle={row.prevalidationErrorMessage}
            iconStyle={{ color: '#D13C3B', marginRight: '5px' }}
            icon={faExclamationTriangle} />
            Failed
          </>;
        }
        else {
          return <FailedLabel />;
        }
      }

      if (row.status === AuditTrailRecordStatus.Processing) return <ProcessingLabel />;

      if (row.status === AuditTrailRecordStatus.Queued) return <QueuedLabel />;

      return <SuccessLabel />;
    },
    disableSortBy: true,
    defaultCanSort: false,
    Filter: ({ column }: any) => {
      return (
        <select onChange={(event) => column.setFilter(Number.parseInt(event.target.value))} style={{ width: '100%' }} value={column.filterValue || ''}>
          <option value="">All</option>
          <option value="1">Queued</option>
          <option value="2">Processing</option>
          <option value="3">Success</option>
          <option value="4">Failed</option>
        </select>
      );
    },
  },
  {
    Header: 'Source',
    id: "source",
    accessor: (row: any) => {
      return AuditTrailSource.Scim === row.source ? "Scim" : "Retry"
    },
    defaultCanSort: false,
    disableSortBy: true,
    width: "3em",
    hidden: true
  },
  {
    Header: 'Action',
    width: "5em",
    disableSortBy: true,
    accessor: (row: any) => {
      switch (row.scimActionType) {
        case ScimActionType.Create:
          return 'Create';

        case ScimActionType.Delete:
          return 'Delete';

        case ScimActionType.Update:
          return 'Update';

        case ScimActionType.Retry:
          return 'Retry';

        default:
          return '';
      }
    },
  },
  {
    Header: 'Related Events',
    accessor: (row: any) => {
      return <RelatedEventsComponent events={row.events} />;
    },
    defaultCanSort: false,
    disableSortBy: true,
  },
];
