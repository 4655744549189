import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TokenFormComponent } from '../components/token-form.component';
import { faChevronRight, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import './AzureOkta.scss';

export const OktaPage = () => {
  return (<div className="permanent-token-page" data-testid="okta-page">
    <h2 className="token-page-header">Okta configuration page</h2>
    <div className="page-instructions">
      <div className="step">
        <span className="step-order">Step 1:</span>
        <p className="step-text">Navigate to Okta <b>App Catalog </b> in the Applications section.</p>
      </div>
      <div className="step">
        <span className="step-order">Step 2:</span>
        <p className="step-text">Find and add <b>PowerDMS </b> application.</p>
      </div>
      <div className="step">
        <span className="step-order">Step 3:</span>
        <p className="step-text">
          Enter your credentials in the form below, generate and paste a token to the <b>Provisioning</b>
          <FontAwesomeIcon className='icon arrow' icon={faChevronRight} color="#000" />
          <b>Integration</b> section of the Okta application.
        </p>
      </div>
    </div>
    <div className="note">
      <div className="note-text">
        <b>Note:</b> Okta updates will be made on behalf of the user whose credentials were used to generate the token.
        Please make sure that the user has necessary privileges to create and edit users and groups.
      </div>
    </div>
    <TokenFormComponent showOnlyInputs={false} showTenantUrl={false} />
    <div className="page-instructions">
      <div className="step">
        <span className="step-order">Step 4:</span>
        <p className="step-text">
          Check needed options (Create Users, Update User Attributes and Deactivate Users) in the application
          <FontAwesomeIcon className='icon arrow' icon={faChevronRight} color="#000"/>
          <b>Provisioning </b>
          <FontAwesomeIcon className='icon arrow' icon={faChevronRight} color="#000"/>
          <b>To App.</b>
        </p>
      </div>
      <div className="warning-text">
        <FontAwesomeIcon icon={faExclamationTriangle} color="#e8bd2b"/>
        <p className="step-text">Don't forget to save the changes!</p>
      </div>
      <div className="step">
        <span className="step-order">Step 5:</span>
        <p className="step-text">
          Assign target users and groups to the application. They will be pushed soon after. Any further updates will
          be pushed automatically by Okta.
        </p>
      </div>
    </div>
    <div className="note">
      <div className="note-text">
        <b>Note:</b> Okta requests will be recorded as a part of "SCIM" Sync in the Sync History Table.
      </div>
    </div>
  </div>)
}
