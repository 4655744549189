import { AxiosInstance } from "axios";
import { FileHeaderMappingResponseModel } from "../../models/file-header-mapping-response.model";
import { FileHeaderMappingModel } from "../../models/file-header-mapping.model";
import { FileValidationModel } from "../../models/file-validation.model";
import { SchemaHeader } from "../../models/schema-header.model";
import { SchemaType } from "../../models/enums/schema-type.enum";
import { ValidationResult } from "../../models/shared/validation-result";
import { SyncStatus } from "../../models/enums/sync-status.enum";
import { getSyncProxyControllerUrl } from "../../services/helper";

export interface FileImportDataSource {
    uploadFile({ file, schemaType, webFormsToken }: { file: File, schemaType: SchemaType, webFormsToken: string }): Promise<FileValidationModel>;
    saveFileMappings({ syncId, mappings, syncName, webFormsToken }: { syncId: string, mappings: FileHeaderMappingModel[], syncName: string, webFormsToken: string }): Promise<FileHeaderMappingResponseModel>;
    getSchemaHeaders({ schemaType, webFormsToken}: {schemaType: SchemaType, webFormsToken: string}) : Promise<SchemaHeader[]>;
}

export class FileImportDataSourceImpl implements FileImportDataSource {
    constructor(private client: AxiosInstance) { };

    async uploadFile({ file, schemaType, webFormsToken }: { file: File, schemaType: SchemaType, webFormsToken: string }): Promise<FileValidationModel> {
        var query = `${getSyncProxyControllerUrl()}/import/`;
        const formData = new FormData();
        formData.append("type", schemaType.id.toString());
        formData.append("file", file);

        const response = await this.client.post<string>(query, formData, {
            headers: { Authorization: 'Bearer ' + webFormsToken }
        });

        if (response.status === 200 || !!(response.data as unknown as FileValidationModel)) {
            return response.data as unknown as FileValidationModel;
        }
        else {
            throw new Error(`Error getting site operations. Status code: ${response.status}`);
        }
    }

    async saveFileMappings({ syncId, mappings, syncName, webFormsToken }: { syncId: string, mappings: FileHeaderMappingModel[], syncName: string, webFormsToken: string }): Promise<FileHeaderMappingResponseModel> {
        var query = `${getSyncProxyControllerUrl()}/import/mappings`;

        const response = await this.client.post<string>(query, {
            syncId: syncId,
            mappings: mappings,
            syncName: syncName,
        }, {
            headers: { Authorization: 'Bearer ' + webFormsToken },
        });

        if (response.status === 200 || !!(response.data)) {
            return FileHeaderMappingResponseModel.fromJson(JSON.stringify(response.data));
        }
        else {
            throw new Error(`Error saving file mappings. Status code: ${response.status}`);
        }
    }

    async getSchemaHeaders({schemaType, webFormsToken}: {schemaType: SchemaType, webFormsToken: string}) : Promise<SchemaHeader[]> {
        var query = `${getSyncProxyControllerUrl()}/import/schemas/${schemaType.id}`;

        const response = await this.client.get<string>(query, {
            headers: { Authorization: 'Bearer ' + webFormsToken },
        });

        if (response.status === 200 || !!(response.data as unknown as SchemaHeader[])) {
            return response.data as unknown as SchemaHeader[];
        }
        else {
            throw new Error(`Error getting schema headers. Status code: ${response.status}`);
        }
    }
}

export class FileImportDataSourceMock implements FileImportDataSource {
    async uploadFile({ file, schemaType, webFormsToken }: { file: File, schemaType: SchemaType, webFormsToken: string }): Promise<FileValidationModel> {
        //let json = '{ "sessionId":"id123", "validationResult": { "isSuccessfull": false, "errorMessages": [ "CSV format violated in row 93: 4 colums present while 3 headers present", "CSV format violated in row 12: 2 colums present while 3 headers present" ] }, "schemaHeaders":[ { "name":"OldUsername", "isRequired": true }, { "name":"NewUsername", "isRequired": true } ], "fileHeaders":[ "EmployeeNumber", "OldLogin", "NewLogin" ] }';
        let json = '{ "sessionId":"1", "validationResult": { "isSuccessfull": true, "errorMessages": [] }, "schemaHeaders":[ { "name":"OldUsername", "isRequired": true }, { "name":"NewUsername", "isRequired": false } ], "fileHeaders":[ "EmployeeNumber", "OldLogin", "NewLogin" ] }';
        return FileValidationModel.fromJson(json);
    }

    async saveFileMappings({ syncId, mappings, webFormsToken }: { syncId: string, mappings: FileHeaderMappingModel[], webFormsToken: string }): Promise<FileHeaderMappingResponseModel> {
        return new FileHeaderMappingResponseModel({
            syncId: syncId,
            sessionId: "1",
            syncStatus: SyncStatus.Queued,
            validationResult: new ValidationResult({ isSuccessful: true, errorMessages: [] })
        });
    }

    async getSchemaHeaders({schemaType, webFormsToken}: {schemaType: SchemaType, webFormsToken: string}) : Promise<SchemaHeader[]> {
        return [new SchemaHeader({isRequired: true, name: "OldUsername", displayName: "Old username", info: null as unknown as string}), 
        new SchemaHeader({isRequired: true, name: "NewUsername", displayName: "New username", info: "Username cannot contain following special symbols: < > ? ' \" # &"})];
    }
}