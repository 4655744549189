import { configureStore } from '@reduxjs/toolkit';
import authReducer from './auth';
import importReducer from './import';
import mainReducer from './main';
import syncSessionsReducer from './sync-sessions';
import tokenReducer from './token';
import scimHistoryReducer from './scim-history';
import auditReducer from './audit'

const store = configureStore({
  reducer: {
    audit: auditReducer,
    auth: authReducer,
    import: importReducer,
    main: mainReducer,
    syncSessions: syncSessionsReducer,
    token: tokenReducer,
    scimHistory: scimHistoryReducer,
    },
    // some of the values we store are based on models, this flag allows us to store them without warnings
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false})
});

export default store;
