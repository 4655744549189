import { SchemaType } from "../models/enums/schema-type.enum";
import container from '../infrastructure';
import './download-file-link.scss';

export const DownloadSchemaFileLink = ({ importSchema, linkName }: { importSchema: SchemaType, linkName: string }) => {
    return <button type="button" className="file-link" onClick={(e) => { e.preventDefault(); downloadClick(importSchema); }}>{linkName}</button>
}

const downloadClick = (importSchema: SchemaType) => {

    var element = document.createElement('a');
    var content = container.fileTemplatesRepository.getFileTemplate({ schema: importSchema });

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', `${importSchema.fileTemplateName}.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}