import { PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { MemoryRepository } from './memory.repository';
import { AuditTrailDataSource } from '../datasources/audit-trail.datasource';
import { AuditTrailRecord } from '../../models/audit-trail-record.model';

export type AuditTrailPaginated = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
  filters: { id: string, filterValue: string | null }[]
};

export interface AuditTrailRepository {
  getAuditTrailPaginated({ pageSize, pageNumber, sortingField, isAscending, filters }: AuditTrailPaginated): Promise<PaginatedResponseModel<AuditTrailRecord>>;
}

export class AuditTrailRepositoryImpl implements AuditTrailRepository {
  constructor(private dataSource: AuditTrailDataSource, private memoryRepository: MemoryRepository) { }

  async getAuditTrailPaginated({ pageSize, pageNumber, sortingField, isAscending, filters }: AuditTrailPaginated): Promise<PaginatedResponseModel<AuditTrailRecord>> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();

      return await this.dataSource.getAuditTrailsPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken, filters });
    } catch (error) {
      return null as unknown as PaginatedResponseModel<AuditTrailRecord>;
    }
  }
}
