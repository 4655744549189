import { FileHeaderMappingResponseModel } from "../../models/file-header-mapping-response.model";
import { FileHeaderMappingModel } from "../../models/file-header-mapping.model";
import { FileValidationModel } from "../../models/file-validation.model";
import { SchemaHeader } from "../../models/schema-header.model";
import { SchemaType } from "../../models/enums/schema-type.enum";
import { FileImportDataSource } from "../datasources/file-import.datasource";
import { MemoryRepository } from "./memory.repository";
import { ValidationResult } from "../../models/shared/validation-result";

export interface FileImportRepository {
  uploadFile({ file, schemaType }: { file: File, schemaType: SchemaType }): Promise<FileValidationModel>;
  saveMappings({ syncId, mappings, syncName }: { syncId: string, mappings: FileHeaderMappingModel[], syncName: string }): Promise<FileHeaderMappingResponseModel>;
  getSchemaHeaders({ schemaType }: { schemaType: SchemaType }): Promise<SchemaHeader[]>;
}

export class FileImportRepositoryImpl implements FileImportRepository {
  constructor(
    private dataSource: FileImportDataSource,
    private memoryRepository: MemoryRepository,
  ) { }

  async uploadFile({ file, schemaType }: { file: File, schemaType: SchemaType }): Promise<FileValidationModel> {
    try {
      let preUploadValidationErrors: string[] = [];
      preUploadValidationErrors = preUploadValidationErrors.concat(FileValidator.validateFileExtension(file.name));
      preUploadValidationErrors = preUploadValidationErrors.concat(FileValidator.validateFileSize(file.size));

      if (preUploadValidationErrors.length > 0) {
          return new FileValidationModel({
            sessionId: null,
            syncId: null,
            fileHeaders: [],
            validationResult: new ValidationResult({
              isSuccessful: false,
              errorMessages: preUploadValidationErrors
            })
          })
      }


      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      const model = await this.dataSource.uploadFile({ file, schemaType, webFormsToken });
      return model;
    } catch (error) {
      return null as unknown as FileValidationModel;
    }
  }

  async saveMappings({ syncId, mappings, syncName }: { syncId: string, mappings: FileHeaderMappingModel[], syncName: string }): Promise<FileHeaderMappingResponseModel> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      const model = await this.dataSource.saveFileMappings({ syncId, mappings, syncName, webFormsToken });
      return model;
    } catch (error) {
      return null as unknown as FileHeaderMappingResponseModel;
    }
  }
  async getSchemaHeaders({ schemaType }: { schemaType: SchemaType }): Promise<SchemaHeader[]> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      const model = await this.dataSource.getSchemaHeaders({ schemaType, webFormsToken });
      return model;
    } catch (error) {
      return null as unknown as SchemaHeader[];
    }
  }
}
const FileValidator = {
  validateFileSize: (fileSize:number) => {
    const size10MB = 10 * 1024 * 1024;
    const errors: string[] = [];
    if (fileSize > size10MB) {
        errors.push(FileValidationMessages.fileTooLargeMessage)
    }
    return errors;
  },
  validateFileExtension: (fileName: string) => {
    const errors: string[] = [];
    const acceptableFormat = ".csv";
    if (!fileName.endsWith(acceptableFormat)) {
      errors.push(FileValidationMessages.supportedFormatMessages)
    }
    return errors;
  }
}

export const FileValidationMessages = {
  fileTooLargeMessage: "The file is too large. The maximum acceptable size is 10 MB.",
  supportedFormatMessages: "Only CSV files are supported."
}