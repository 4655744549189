import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SchemaHeader } from '../models/schema-header.model';
import { SyncSessionInfo } from '../models/sync-session-info.model';
import { SchemaDictionary } from '../store/import';
import { getSchema } from '../store/import-actions';
import './sync-session-form.scss'

export interface EventInterface {
    target: any
}

const SyncSessionForm = ({ syncSession }: { syncSession: SyncSessionInfo }) => {
    const dispatch = useDispatch();
    const parsedMappings = !!syncSession.mappings ? JSON.parse(syncSession.mappings) : {}
    const fileHeaders: string[] = Object.values(parsedMappings).map(x => x as string);
    const schemas: SchemaDictionary = useSelector((state: any) => state.import.importSchemas) as SchemaDictionary;
    const schemaHeaders: SchemaHeader[] | null = schemas[syncSession.type.id]

    useEffect(() => {
        if (schemaHeaders === null) {
            const schemaType = syncSession.type;
            dispatch(getSchema({ schemaType }));
        }
    }, [syncSession, schemaHeaders, dispatch])

    const mappingColumns = schemaHeaders?.map((header: SchemaHeader) =>
        <li key={header.name}>
            <p>{header.displayName} </p>
            <select disabled name={header.name} id={`ii-${header.name}`} value={parsedMappings[header.name]}>
                <option value="" />
                {fileHeaders.map((value: string) => <option key={value} value={value} >{value}</option>)}
            </select>
        </li>
    ) ?? [];

    return (
        <section className="pageSectionWrapper">
            <div className="sync-session-form-page">
                <form className="fileSyncForm">
                    <div className="fileSyncForm--row">
                        <label htmlFor="syncType" className="fileSyncForm--label">
                            Import Type:
                        </label>
                        <label id="syncType" className="fileSyncForm--label" style={{textAlign:"left"}}>{syncSession.type.name}</label>
                    </div>
                    <div className="fileSyncForm--row">
                        <label htmlFor="createdBy" className="fileSyncForm--label">
                            Created by:
                        </label>
                        <label id="createdBy" className="fileSyncForm--label" style={{textAlign:"left"}}>{syncSession.userName}</label>
                    </div>
                    <div className="fileSyncForm--row">
                        <label htmlFor="lastRun" className="fileSyncForm--label">
                            Last run:
                        </label>
                        <label id="lastRun" className="fileSyncForm--label" style={{textAlign:"left"}}>{syncSession.creationDate?.toLocaleString() ?? '—'}</label>
                    </div>
                    <div className="fileSyncForm--row">
                        <label className="fileSyncForm--label">
                            Field Mappings:
                        </label>
                        <div>
                            <ul className="fieldsGrid">
                                {mappingColumns}
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </section>
    );
};

export default SyncSessionForm;