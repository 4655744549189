import { createSlice } from '@reduxjs/toolkit';
import { AuditTrailRecord } from '../models/audit-trail-record.model';

const initialAuditState: AuditState = {
    totalItemsCount: 1,
    pageIndex: 1,
    pageSize: 25,
    items: [],
    error: null,
};

const auditSlice = createSlice({
  name: 'audit',
  initialState: initialAuditState,
  reducers: {
    handleGetAuditTrail(state, action) {
      state.totalItemsCount = action.payload.totalItemsCount;
      state.pageIndex = action.payload.pageIndex;
      state.pageSize = action.payload.pageSize;
      state.items = action.payload.items;
    },
    handleSetError(state, action) {
        state.error = action.payload;
    }
  },
});

export interface AuditState {
    totalItemsCount: number;
    pageIndex: number;
    pageSize: number;
    items: AuditTrailRecord[];
    error: Error|null;
}

export const auditActions = auditSlice.actions;

export default auditSlice.reducer;
