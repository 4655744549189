import { ModalProps } from '../models/modal-props.model';
import { mainActions } from './main';
import { abortSession, reloadHistoryData} from './sync-sessions-actions';
import { cancelResumeScimSync, retryScimSync } from './scim-history-actions';

export const showNotification = (notificationMessage: string) => {
  return async (dispatch: Function) => {
        dispatch(mainActions.handleShowNotification(notificationMessage));
  };
};

export const hideNotification = () => {
    return async (dispatch: Function) => {
          dispatch(mainActions.handleHideNotification());
    };
  };

export const setInDevelopmentMode = () => {
    return async (dispatch: Function) => {
        dispatch(mainActions.handleSetInDevelopmentMode());
  };
}

export const setRoutePrefix = (routePrefix: string) => {
  return async (dispatch: Function) => {
    dispatch(mainActions.handleSetRoutePrefix(routePrefix));
  };
}

export const showModalCancelSession  = (sessionId: string) => {
  return async (dispatch: Function) => {
    const modalProps: ModalProps = {
      isModalOpen: true,
      modalHeader: 'Cancel Sync',
      modalText:  [`Stopping this in progress sync will not remove users and groups already added. You cannot restart this sync instance once it is canceled.`,
      `The process may take several seconds to complete.`,
      `Are you sure you want to cancel this sync?`],
      modalPrimaryButtonText: 'Cancel Now',
      modalSecondaryButtonEnabled: true,
      modalSecondaryButtonText: 'Continue Running Sync',
      handleModalSubmit: async () => {
        dispatch(abortSession({sessionId}));
      },
      handleModalClose: () => dispatch(mainActions.handleHideModal()),
      handleModalSecondary: () => dispatch(mainActions.handleHideModal()),
      modalErrorsText: '',
      primaryButtonClasses: "delete-button"
    }

    dispatch(reloadHistoryData());
    dispatch(mainActions.handleSetModalProps(modalProps));
  };
}

export const showModalCancelScimSync  = () => {
  return async (dispatch: Function) => {
    const modalProps: ModalProps = {
      isModalOpen: true,
      modalHeader: 'Cancel SCIM',
      modalText:  [`This will disable all incoming requests that may come from different sources. It will not revert already applied changes. Do you want to proceed?`],
      modalPrimaryButtonText: 'Proceed',
      modalSecondaryButtonEnabled: true,
      modalSecondaryButtonText: 'Go Back',
      handleModalSubmit: async () => {
        dispatch(cancelResumeScimSync(false));
      },
      handleModalClose: () => dispatch(mainActions.handleHideModal()),
      handleModalSecondary: () => dispatch(mainActions.handleHideModal()),
      modalErrorsText: '',
      primaryButtonClasses: 'delete-button'
    }

    dispatch(reloadHistoryData());
    dispatch(mainActions.handleSetModalProps(modalProps));
  };
}

export const showModalRetryScimSync  = (sessionId: string) => {
  return async (dispatch: Function) => {
    const modalProps: ModalProps = {
      isModalOpen: true,
      modalHeader: 'Retry SCIM',
      modalText:  [`This will retry the processing of the event that failed. Do you want to proceed?`],
      modalPrimaryButtonText: 'Proceed',
      modalSecondaryButtonEnabled: true,
      modalSecondaryButtonText: 'Go Back',
      handleModalSubmit: async () => {
        dispatch(retryScimSync(sessionId));
      },
      handleModalClose: () => dispatch(mainActions.handleHideModal()),
      handleModalSecondary: () => dispatch(mainActions.handleHideModal()),
      modalErrorsText: '',
      primaryButtonClasses: ''
    }

    dispatch(reloadHistoryData());
    dispatch(mainActions.handleSetModalProps(modalProps));
  };
}

export const hideModal  = () => {
  return async (dispatch: Function) => {
    dispatch(mainActions.handleHideModal());
  };
}

export const setModalErrors  = (error: string) => {
  return async (dispatch: Function) => {
    dispatch(mainActions.handleSetModalErrors(error));
  };
}
