export interface FileTemplatesDataSource {
    getImportUsersTemplate(): string;
    getImportGroupsTemplate(): string;
    getUpdateUsernamesTemplate(): string;
    getUpdateGroupnamesTemplate(): string;

    getRecurrentScriptSchedulerTemplate(): string;
}

export class FileTemplatesDataSourceImpl implements FileTemplatesDataSource {
    getImportUsersTemplate(): string {
        return `Username,FirstName,LastName,MiddleName,Email,JobTitle,Location,Address,City,State,Zipcode,Phone,Password,GroupMemberships,Disabled
jgalt,John,Galt,Doctor,jgalt@mail.com,Lead Engineer,I-1,Grove Str.,Istanbul,IST,123123,123-123-12,Aa-123456?!,"Engineers,Managers",False
jdoe,John,Doe,Toe,jdoe@mail.com,Manager,NYC-2,Elm Str.,New York City,New York,456456,456-456-45,V@l1dPa@$sw0rd,Managers,True        
`;
    }

    getImportGroupsTemplate(): string {
        return `GroupName,GroupMemberships
Engineers,"Team1,Managers"
Managers,Team1
Team1,
`;
    }

    getUpdateUsernamesTemplate(): string {
        return `OldUsername,NewUsername
jgalt,john-galt        
`;
    }

    getUpdateGroupnamesTemplate(): string {
        return `OldGroupName,NewGroupName
Team1,TeamOne
`;
    }

    getRecurrentScriptSchedulerTemplate(): string {
        return `#This should be set as console command parameters
param([string]$uploadfilePath, [validateset("Create","Delete","Check","Create-run")] [string] $mode = "Create")

#These will be populated during generation on web page
$syncId = "{0}"
$token = "{1}"
$endpointUrl = "{2}"
$schemaType = "{3}"

$TempFolderPath = Join-Path -Path $env:APPDATA -ChildPath "User Sync"
$scriptName = "Recurrent-Sync-$syncId.ps1"
$scriptPath = Join-Path -Path $TempFolderPath -ChildPath $scriptName
        
function DeleteFileIfExists {
    param ([string]$scriptPath)
    if (Test-Path $scriptPath) {
        Remove-Item $scriptPath
        Write-Output "Existing script was deleted"
    }
    else {
        Write-Output "No script files were found"
    }
}

function CreateScriptFile {
    param ([string]$folderPath, [string] $scriptName, [string] $scriptBody)
    if (-Not (Test-Path $folderPath)) {
    New-Item -Path $env:APPDATA -Name "User Sync" -ItemType "directory" -Value $scriptBody
    Write-Output "Script directory is created"
    }
    New-Item -Path $folderPath -Name $scriptName -ItemType "file" -Value $scriptBody
    Write-Output "Script file is saved"
}

function CreateScriptBody {
    param ([string]$syncId, [string] $token)
    $body = @"
    Add-Type -AssemblyName 'System.Net.Http'
\`$uploadEnpoint = \`"$endpointUrl\`"
\`$boundary = [System.Guid]::NewGuid().ToString(); 
\`$fileStream = [System.Text.Encoding]::GetEncoding("UTF-8").GetString([System.IO.File]::ReadAllBytes("$uploadfilePath"))

\`$LF = "\`\`r\`\`n";
\`$bodyLines = (
    \`"--\`$boundary\`",
    \`"Content-Disposition: form-data; name=\`\`"syncId\`\`"\`$LF\`",
    \`"$syncId\`",
    \`"--\`$boundary\`",
    \`"Content-Disposition: form-data; name=\`\`"file\`\`"; filename=\`\`"temp.csv\`\`"\`",
    \`"Content-Type: application/octet-stream\`$LF\`",
    \`$fileStream,
    \`"--\`$boundary--\`$LF\`"
) -join \`$LF
        
\`$response = Invoke-RestMethod -Uri \`$uploadEnpoint -Method Post -Headers @{'Authorization' = \`"Bearer $token\`"; 'Content-Type' = \`"multipart/form-data; boundary=\`$boundary\`"} -Body \`$bodyLines
Write-Output \`$response
"@;
    return $body
}
        
function TryDeleteTask{
    param ([string] $taskName)
    try {
        $scheduledTask = Get-ScheduledTask -TaskName $taskName -ErrorAction Stop
        if ($scheduledTask -ne $null) {
            Write-Output "Removing already registered task..."
            Unregister-ScheduledTask -TaskName $taskName
            Write-Output "Existing task was deleted"
        }
    }
    catch {
        Write-Output "No registered tasks were found"
    }
}

function ValidateSyncProcess{
    param([string] $scriptPath, [string] $taskName)
    if ($uploadfilePath -eq ""){
        Write-Warning "Upload file path is not specified"
    }
    else {
        if (-Not(Test-Path $uploadfilePath -PathType Leaf)) {
            Write-Warning "Specified upload file was not found"
        }
        else {
            Write-Output "Specified upload file was found"
        }
    }

    if (-Not(Test-Path $scriptPath -PathType Leaf)) {
        Write-Warning "Sync script file was not found"
    }
    else {
        Write-Output "Sync script file was found"
    }

    try {
        $scheduledTask = Get-ScheduledTask -TaskName $taskName -ErrorAction Stop
        if ($scheduledTask -ne $null) {
            Write-Output "Sync task is scheduled"
        }
    }
    catch { 
        Write-Warning "No registered tasks were found"
    }
}

$taskPath = "\\PowerDMS\\"
$taskName = "Recurrent Sync Task $syncId"

switch ($mode) {
    "Delete" {
        DeleteFileIfExists $scriptPath
        TryDeleteTask $taskName
    }
    "Check" {
        ValidateSyncProcess $scriptPath $taskName
    }
    Default {
        if ($uploadfilePath -eq ""){
            throw "Upload file path is not specified"
        }
        if (-Not(Test-Path $uploadfilePath -PathType Leaf)) {
            Write-Warning "The specified file could not be found"
        }

        DeleteFileIfExists $scriptPath
        $scriptBody = CreateScriptBody $syncId $token
        CreateScriptFile $TempFolderPath $scriptName $scriptBody

        $taskAction = New-ScheduledTaskAction -Id "RecurrentSync-$syncId" \`
            -Execute "Powershell.exe" \`
            -Argument "-windowstyle hidden -executionpolicy bypass -file \`"$scriptPath\`""

        $taskTrigger = if ($schemaType -eq "Import Groups") { New-ScheduledTaskTrigger -Daily -At "1:59 PM" } else { New-ScheduledTaskTrigger -Daily -At "2:00 PM" }
        $taskSettings = New-ScheduledTaskSettingsSet

        TryDeleteTask $taskName

        Register-ScheduledTask -TaskName $taskName -Action $taskAction -Trigger $taskTrigger -Settings $taskSettings -TaskPath $taskPath
        Write-Output "New task registered"

        if ($mode -eq "Create-run"){
            Write-Output "Running the task..."
            Start-ScheduledTask -TaskName $taskName -TaskPath $taskPath
        }
    }
}
`;
    }
}