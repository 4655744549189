import { useDispatch, useSelector } from "react-redux"
import { SyncSessionLogsComponent } from "../components/sync-session-logs.component"
import { NavTab } from "react-router-tabs"
import { Redirect, Route, Switch, useParams } from "react-router";
import "../components/nav-tabs.scss";
import "./SyncSession.scss";
import { useEffect } from "react";
import { SyncSessionInfo } from "../models/sync-session-info.model";
import { getSessionInfo } from "../store/sync-sessions-actions";
import SyncSessionForm from "../components/sync-session-form.component";
import { RecurrentSyncComponent } from "../components/recurrent-sync.component";

export const SyncSessionPage = () => {
  const dispatch = useDispatch();
  const { sessionId }: { sessionId: string } = useParams();

  useEffect(() => {
    dispatch(getSessionInfo({sessionId}));
  }, [dispatch, sessionId])

  const routePrefix: string = useSelector((state: any) => state.main.routePrefix);
  const sessionInfo: SyncSessionInfo = useSelector((state: any) => state.syncSessions.sessionInfo);

  return (<div className="syncSessionPage">
    <h3 className="header break-line">{sessionInfo?.name}</h3>
    <NavTab to={`${routePrefix}/session/${sessionId}/info`}>Setup</NavTab>
    <NavTab to={`${routePrefix}/session/${sessionId}/recurrent`}>Recurrent Import</NavTab>
    <NavTab to={`${routePrefix}/session/${sessionId}/logs`}>Logs</NavTab>
    <div className="syncSessionContainer">
    <Switch>
      <Route path={`${routePrefix}/session/${sessionId}/info`}>
        {!!sessionInfo 
          ?
            <SyncSessionForm {...{syncSession:sessionInfo}} />
          : 
            <h2 className="error-message">No session found!</h2>
        }
        
      </Route>
      <Route path={`${routePrefix}/session/${sessionId}/logs`}>
        <SyncSessionLogsComponent sessionId={sessionId} />
      </Route>
      <Route path={`${routePrefix}/session/${sessionId}/recurrent`}>
        <RecurrentSyncComponent syncId={sessionInfo?.syncId} schemaType={sessionInfo?.type?.name}/>
      </Route>
      <Route>
        <Redirect to={`${routePrefix}/session/${sessionId}/info`}/>
      </Route>
    </Switch>
    </div>
  </div>)
}