import { AxiosInstance } from "axios";
import { HttpResponseModel } from "../../models/shared/http-response.model";
import { TokenResponseModel } from "../../models/token-response.model";
import { getSyncProxyControllerUrl } from "../../services/helper";

export interface TokenDataSource {
    getToken({ userName, password, siteKey, cookieBasedToken }: { userName: string, password: string, siteKey: string, cookieBasedToken: string }): Promise<HttpResponseModel<TokenResponseModel>>;
}

export class TokenDataSourceImpl implements TokenDataSource {
    constructor(private client: AxiosInstance) { };
    async getToken({ userName, password, siteKey, cookieBasedToken }: { userName: string, password: string, siteKey: string, cookieBasedToken: string }): Promise<HttpResponseModel<TokenResponseModel>> {
        var query = `${getSyncProxyControllerUrl()}/api/token/`;
        const data = { userName, password, siteKey };

        const response = await this.client.post<string>(query, data,{
            headers: { Authorization: 'Bearer ' + cookieBasedToken }
        });

        return new HttpResponseModel<TokenResponseModel>({
            isSuccessful: response.status === 200,
            //@ts-ignore
            data: new TokenResponseModel(response.data)
        });
    }
}

//@ts-ignore
export class TokenDataSourceMock implements TokenDataSource {
    async getToken({ userName, password, siteKey, cookieBasedToken }: { userName: string, password: string, siteKey: string, cookieBasedToken: string }): Promise<HttpResponseModel<TokenResponseModel>> {
        return new HttpResponseModel<TokenResponseModel>({
            isSuccessful: true,
            data: new TokenResponseModel({
                token: "response.data.token",
                errors: []
            })
        });
    }
}