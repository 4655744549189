import { IPaginatedResponse, PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { AxiosInstance } from 'axios';
import { getSyncProxyControllerUrl } from '../../services/helper';
import { AuditTrailRecord } from '../../models/audit-trail-record.model';

export type AuditTrailPaginatedDataSource = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
  webFormsToken: string;
  filters: { id: string, filterValue: string | null }[]
};

export interface AuditTrailDataSource {
  getAuditTrailsPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken, filters }: AuditTrailPaginatedDataSource): Promise<PaginatedResponseModel<AuditTrailRecord>>;
}

export class AuditTrailDatasourceImpl implements AuditTrailDataSource {
  constructor(private client: AxiosInstance) { }

  async getAuditTrailsPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken, filters }: AuditTrailPaginatedDataSource): Promise<PaginatedResponseModel<AuditTrailRecord>> {
    const query = `${getSyncProxyControllerUrl()}/audit-trail`;
    const data = {
      pageSize,
      pageNumber: pageNumber + 1,
      sortingField,
      isAscending,
      filters
    };

    const response = await this.client.post<IPaginatedResponse<AuditTrailRecord>>(query, data, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
    });

    if (response.status === 200 || !!response.data) {
      const { data } = response;
      return {
        pageSize: data.pageSize,
        pageIndex: data.pageNumber,
        totalItemsCount: data.totalCount,
        items: data.items,
      };
    } else {
      throw new Error(`Error getting site operations. Status code: ${response.status}`);
    }
  }
}
