import { createSlice } from '@reduxjs/toolkit';
import { SchemaType } from '../models/enums/schema-type.enum';
import { SchemaHeader } from '../models/schema-header.model';

const initialState: ImportFileState = {
    fileErrors: [],
    schemaHeaders: [],
    fileHeaders: [],
    fileName: null as unknown as string,
    sessionId: null as unknown as string,
    syncId: null as unknown as string,
    shouldRedirect: false,
    importSchema: null as unknown as SchemaType,
    importSchemas: SchemaType.GetAllToDisplay().map(x => x.id).reduce((a,v) => ({...a, [v]: null } ), {}) as SchemaDictionary,
    recurrentSchedulerScript: null,
}

const importSlice = createSlice({
    name: 'import',
    initialState: initialState,
    reducers: {
        handleFileUpload(state, action) {
            state.syncId = action.payload.syncId;
            state.sessionId = action.payload.sessionId;
            state.fileErrors = action.payload.errorMessages ?? [];
            state.fileHeaders = action.payload.fileHeaders ?? [];
        },
        handleSetFileName(state, action) {
            state.fileName = action.payload;
        },
        handleSetShouldRedirect(state, action) {
            state.shouldRedirect = action.payload;
        },
        handleSetFileErrors(state, action) {
            state.fileErrors = action.payload;
        },
        handleSetSchemaHeaders(state, action) {
            state.schemaHeaders = action.payload ?? [];
        },
        handleLoadSchema(state, action) {
            const {id, headers} = action.payload;
            state.importSchemas[id] = headers;
        },
        handleResetFile(state) {
            state.fileName = null as unknown as string;
            state.fileErrors = [];
            state.fileHeaders = [];
        },
        handleGetRecurrentSchedulerScript(state, action) {
            state.recurrentSchedulerScript = action.payload;
        },
    },
});

export interface SchemaDictionary {
    [schemaId: number]: SchemaHeader[]|null;
}

export interface ImportFileState {
    fileErrors: string[];
    schemaHeaders: string[];
    fileHeaders: string[];
    fileName: string;
    sessionId: string;
    syncId: string;
    shouldRedirect: boolean;
    importSchema: SchemaType;
    importSchemas: SchemaDictionary;
    recurrentSchedulerScript: string|null;
}

export const importActions = importSlice.actions;

export default importSlice.reducer;
