import { Route, Switch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import { AuditTrail } from "./audit-trail";
import { EntityRetries } from "./entity-retries";
import { SyncSessionsHistory } from "./sync-sessions-history";
import './home-page-tabs.scss';

export interface HomePageTabsProps {
    routePrefix: string;
}

export const HomePageTabs = (props: HomePageTabsProps) => {
    return <>
        <div className="home-tabs">
            <NavTab key="audit" exact to={`${props.routePrefix}/`}><h4 className="tab-title">Incoming Requests</h4></NavTab>
            <NavTab key="entity-failures" to={`${props.routePrefix}/entity-retries`}><h4 className="tab-title">Provisioning Failures</h4></NavTab>
            <NavTab key="file-history" to={`${props.routePrefix}/sync-history`}><h4 className="tab-title">File Import History</h4></NavTab>
        </div>
        <div className="body">
            <Switch>
                <Route exact key='audit' path={`${props.routePrefix}/`}>
                    <AuditTrail/>
                </Route>
                <Route exact key='entity-failures' path={`${props.routePrefix}/entity-retries`}>
                    <EntityRetries/>
                </Route>
                <Route exact key='file-history' path={`${props.routePrefix}/sync-history`}>
                    <SyncSessionsHistory/>
                </Route>
            </Switch>
        </div>
    </>;
}