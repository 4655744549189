import { ScimEntityType } from "./scim-entity-type.model";

export interface AuditTrailRecord {
  createdAt: number;
  auditId: string;
  scimEntityType: ScimEntityType;
  scimActionType: ScimActionType;
  scimResponseHttpStatusCode: number;
  prevalidationErrorMessage: string | null;
  status: AuditTrailRecordStatus;
  source: AuditTrailSource;
  affectedEntityName: string | null;

  events: AuditTrailProjectedEventModel[];
}

export interface AuditTrailProjectedEventModel {
  timeStamp: number;
  processingStartedAt: number | null;
  processingEndedAt: number | null;
  userSyncErrorMessage: string | null;
  ccmpHttpRequestStatusCode: number | null;
  ccmpHttpRequestErrorMessages: string[];
  ccmpHttpRequestErrorCodes: string[];
  status: AuditTrailProjectedEventStatus;
  eventType: string;
}

export enum AuditTrailRecordStatus {
  Queued = 1,
  Processing = 2,
  Success = 3,
  Failed = 4,
}

export enum AuditTrailProjectedEventStatus {
  Processing = 1,
  Success = 2,
  Failed = 3,
}

export enum AuditTrailSource {
  Scim = 1,
  ManualRetry = 2,
}

export enum ScimActionType {
  Create = 1,
  Update = 2,
  Delete = 3,
  Retry = 4,
}
