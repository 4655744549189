import { AxiosInstance } from "axios";
import { SyncSessionInfo } from "../../models/sync-session-info.model";
import { getSyncProxyControllerUrl } from "../../services/helper";
import { SyncLogRecord } from "../../models/sync-log-record.model";
import { PaginatedResponseModel } from "../../models/shared/paginated-response.model";
import { HttpResponseModel } from "../../models/shared/http-response.model";

export interface SyncSessionsDataSource {
    getSessionInfo({sessionId, webFormsToken}: { sessionId: string, webFormsToken: string }): Promise<SyncSessionInfo>;
    getHistoryPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken }:
        { pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean, webFormsToken: string }): Promise<PaginatedResponseModel<SyncSessionInfo>>;
    getSessionLogsPaginated({ sessionId, pageSize, pageNumber, sortingField, isAscending, webFormsToken }:
        { sessionId: string, pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean, webFormsToken: string }): Promise<PaginatedResponseModel<SyncLogRecord>>;
    abortSession({sessionId, webFormsToken}: { sessionId: string, webFormsToken: string }): Promise<HttpResponseModel<string>>;
}

//Backend will be implemented later
export class SyncSessionsDataSourceImpl implements SyncSessionsDataSource {
    constructor(private client: AxiosInstance) { };

    async getSessionInfo({sessionId, webFormsToken}: { sessionId: string, webFormsToken: string }): Promise<SyncSessionInfo> {
        var query = `${getSyncProxyControllerUrl()}/dashboard/sessions/${sessionId}`;

        const response = await this.client.get<string>(query, {
            headers: { Authorization: 'Bearer ' + webFormsToken }
        });

        if (response.status === 200 || !!(response.data)) {
            return (SyncSessionInfo.fromJson(JSON.stringify(response.data)));
        }
        else {
            throw new Error(`Error getting site operations. Status code: ${response.status}`);
        }
    }

    async getHistoryPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken }:
        { pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean, webFormsToken: string }): Promise<PaginatedResponseModel<SyncSessionInfo>> {
        var query = `${getSyncProxyControllerUrl()}/dashboard/sessions/`;
        var data = {
            pageSize: pageSize,
            pageNumber: pageNumber + 1,
            sortingField: sortingField,
            isAscending: isAscending
        };
        const response = await this.client.post<string>(query, data, {
            headers: { Authorization: 'Bearer ' + webFormsToken }
        });

        if (response.status === 200 && !!(response.data)) {
            return PaginatedResponseModel.fromJson<SyncSessionInfo>(JSON.stringify(response.data), SyncSessionInfo.fromJObj);
        }
        else {
            throw new Error(`Error getting site operations. Status code: ${response.status}`);
        }
    }

    async getSessionLogsPaginated({ sessionId, pageSize, pageNumber, sortingField, isAscending, webFormsToken }:
        { sessionId: string, pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean, webFormsToken: string }): Promise<PaginatedResponseModel<SyncLogRecord>> {
        var query = `${getSyncProxyControllerUrl()}/dashboard/sessions/${sessionId}/logs`;
        var data = {
            pageSize: pageSize,
            pageNumber: pageNumber + 1,
            sortingField: sortingField,
            isAscending: isAscending
        };

        const response = await this.client.post<string>(query, data, {
            headers: { Authorization: 'Bearer ' + webFormsToken }
        });

        if (response.status === 200 || !!(response.data)) {
            return PaginatedResponseModel.fromJson<SyncLogRecord>(JSON.stringify(response.data), SyncLogRecord.fromJObj);
        }
        else {
            throw new Error(`Error getting site operations. Status code: ${response.status}`);
        }
    }

    async abortSession({sessionId, webFormsToken}: { sessionId: string, webFormsToken: string }): Promise<HttpResponseModel<string>> {
        var query = `${getSyncProxyControllerUrl()}/dashboard/sessions/${sessionId}/abort`;
        const response = await this.client.delete<string>(query, {
            headers: { Authorization: 'Bearer ' + webFormsToken }
        });
        return new HttpResponseModel<string> ({
            isSuccessful: response.status === 200,
            data: response.data
         });
    }
}
