import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './breadcrumb.scss';

export const BreadcrumbComponent = () => {
    const routePrefix = useSelector((state: any) => state.main.routePrefix);
    const location = useLocation();
    const shouldBeDisplayed = useMemo(() => {
        return location.pathname !== "/" && location.pathname !== "/sync" && location.pathname !== "/sync/";
    }, [location.pathname])

    return (
        <div className='back-to-link'>
            {
                shouldBeDisplayed &&
                <Link to={`${routePrefix}/`}>
                    <FontAwesomeIcon className="icon-back" icon={faLongArrowAltLeft} />
                    Back to User Sync
                </Link>
            }
        </div>
    )
}
