import { HttpResponseModel } from "../../models/shared/http-response.model";
import { PaginatedResponseModel } from "../../models/shared/paginated-response.model";
import { SyncLogRecord } from "../../models/sync-log-record.model";
import { SyncSessionInfo } from "../../models/sync-session-info.model";
import { SyncSessionsDataSource } from "../datasources/sync-sessions.datasource";
import { MemoryRepository } from "./memory.repository";

export interface SyncSessionsRepository {
    getSessionInfo({sessionId}: {sessionId: string}): Promise<SyncSessionInfo>;
    getHistoryPaginated({pageSize, pageNumber, sortingField, isAscending}:
      {pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean}): Promise<PaginatedResponseModel<SyncSessionInfo>>;
    getSessionLogsPaginated({sessionId, pageSize, pageNumber, sortingField, isAscending}:
      {sessionId: string, pageSize: number, pageNumber: number, sortingField: string, isAscending: boolean}) : Promise<PaginatedResponseModel<SyncLogRecord>>;

    abortSession({sessionId}: { sessionId: string }): Promise<HttpResponseModel<string>>;
  }

     export class SyncSessionsRepositoryImpl implements SyncSessionsRepository {
  constructor(
    private dataSource: SyncSessionsDataSource,
    private memoryRepository: MemoryRepository,
    ) { }

  async getSessionInfo({sessionId}: {sessionId: string}): Promise<SyncSessionInfo> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      return await this.dataSource.getSessionInfo({sessionId, webFormsToken});
    }
    catch (error) {
      return null as unknown as SyncSessionInfo;
    }
  }

  async getHistoryPaginated({pageSize, pageNumber, sortingField, isAscending}:
     {pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean}): Promise<PaginatedResponseModel<SyncSessionInfo>> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      return await this.dataSource.getHistoryPaginated({pageSize, pageNumber, sortingField, isAscending, webFormsToken});
    } catch (error) {
      return null as unknown as PaginatedResponseModel<SyncSessionInfo>;
    }
  }

  async getSessionLogsPaginated({sessionId, pageSize, pageNumber, sortingField, isAscending}:
    {sessionId: string, pageSize: number, pageNumber: number, sortingField: string, isAscending: boolean}) : Promise<PaginatedResponseModel<SyncLogRecord>> {
      try {
        const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
        return await this.dataSource.getSessionLogsPaginated({sessionId, pageSize, pageNumber, sortingField, isAscending, webFormsToken});
      } catch (error) {
        return null as unknown as PaginatedResponseModel<SyncLogRecord>;
      }
    }

    async abortSession({sessionId}: { sessionId: string }): Promise<HttpResponseModel<string>> {
        const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
        return this.dataSource.abortSession({sessionId, webFormsToken});
    }
}
