import { authActions } from './auth';
import { HttpService } from '../services/http-service';
import { getApiBaseUrl } from '../services/helper';
import container from '../infrastructure';

export const getAuthorizationLink = () => {
  return async (dispatch: Function) => {
    try {
      const response = await HttpService.get<string>(`${getApiBaseUrl()}/proxy/scimapi/api/universalloginurl`);

      dispatch(authActions.handleGettingAuthLink(response.data));
    } catch (err) {
      console.error(`Error retrieving data from syncApi with message ${err}`);
    }
  };
};

export const getAuthorizationTokenByCredentials = (code: string) => {
  return async (dispatch: Function) => {
    try {
      const response = await HttpService.post(`${getApiBaseUrl()}/proxy/scimapi/api/authtoken`, {
        AuthorizationCode: code,
        // @ts-ignore
        SiteId: `${window.siteProfile?.siteId}`,
      });

      dispatch(authActions.handleAuthorizationToken(response.data));
    } catch (err) {
      console.error(`Error retrieving data from syncApi with message ${err}`);
    }
  }
};

export const getAuthenticationTokenFromWebForms = () => {
  return async (dispatch: Function) => {
    try {
      const token: string = await container.webformsTokenRepository.getAuthenticationTokenFromWebForms()
      dispatch(authActions.handleWebFormsToken(token));
    } catch (err) {
      console.error(`Error retrieving data from syncApi with message ${err}`);
    }
  };
}
