export interface IPaginatedResponse<T> {
  pageSize: number;
  pageNumber: number;

  totalCount: number;
  items: T[];
}

export class PaginatedResponseModel<T> {
  public pageSize: number;
  public pageIndex: number;

  public totalItemsCount: number;
  public items: T[];

  public constructor({ pageSize, pageIndex, totalItemsCount, items }: { pageSize: number; pageIndex: number; totalItemsCount: number; items: T[] }) {
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;
    this.totalItemsCount = totalItemsCount;
    this.items = items;
  }

  public static fromJson<T>(json: any, fromJObjCallback: (json: string) => T): PaginatedResponseModel<T> {
    const dto = JSON.parse(json);
    let items: T[] = [];

    if (dto.items.length > 0) {
      items = (dto['items'] as any[]).map((i) => fromJObjCallback(i));
    }

    return {
      pageSize: dto['pageSize'],
      pageIndex: dto['pageNumber'],
      totalItemsCount: dto['totalCount'],
      items: items,
    } as PaginatedResponseModel<T>;
  }
}
