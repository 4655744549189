import { BaseEnum } from "./base.enum";

export class SyncLogSeverity extends BaseEnum {

  public static readonly Processing = new SyncLogSeverity({id: 1, name: "Processing"});
  public static readonly ProcessingWithWarnings = new SyncLogSeverity({id: 2, name: "Processing with warnings"});
  public static readonly ProcessingWithErrors = new SyncLogSeverity({id: 3, name: "Processing with errors"});
  public static readonly Completed = new SyncLogSeverity({id: 11, name: "Completed"});
  public static readonly CompletedWithWarnings = new SyncLogSeverity({id: 12, name: "Completed with warnings"});
  public static readonly CompletedWithErrors = new SyncLogSeverity({id: 13, name: "Partially completed"});
  public static readonly Failed = new SyncLogSeverity({id: 14, name: "Failed"});
  public static readonly Canceled = new SyncLogSeverity({id: 15, name: "Canceled"});
  public static readonly Skipped = new SyncLogSeverity({id: 16, name: "Skipped"});

  public static GetAll(): SyncLogSeverity[] {return [this.Processing, this.ProcessingWithWarnings, this.ProcessingWithErrors, this.Completed, this.CompletedWithWarnings, this.CompletedWithErrors, this.Failed, this.Canceled, this.Skipped];}
}