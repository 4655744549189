import './tooltip.scss';
import { ReactElement } from 'react';

export interface TooltipInterface {
  title: string,
  subtitle?: string,
  element: ReactElement
}

const Tooltip = (props: TooltipInterface) => {
  const { title, subtitle, element } = props;

  if (!title ) {
    return null;
  }

  return (
    <div className="simple-tooltip">
      <span className="body">
        {element}
      </span>

      <div className="tooltip">
        <p className="title">{title}</p> {
            !!subtitle &&
            <p className="subtitle">{subtitle}</p>
        }
      </div>
    </div>
  );
};

export default Tooltip;
