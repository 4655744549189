import './download-file-link.scss';

export const DownloadFileLink = ({ content, linkName, fileName, onClickCallback }: { content: string, linkName: string, fileName: string, onClickCallback?: any }) => {
    return <button type="button" className="file-link" onClick={(e) => { e.preventDefault(); downloadClick(content, fileName, onClickCallback); }}>{linkName}</button>
}

const downloadClick = (content: string, fileName: string, onClickCallback: any) => {

    var element = document.createElement('a');

    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(content));
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);

    if (onClickCallback !== undefined && onClickCallback !== null) {
        onClickCallback();
    }
}