import axios from 'axios';

const HttpService = axios.create({
  withCredentials: true,
});

HttpService.interceptors?.request?.use?.(addCsrfToHeaders);

export function addCsrfToHeaders(config : any) {
  // @ts-ignore
  const app = window.top.store?.getState?.()?.app;

  config.headers['CSRF-TOKEN'] = app?.csrfToken;
  config.headers['X-CSRF-HASH'] = app?.xCsrfHash;
  return config;
}

export { HttpService };
