export interface MemoryRepository {
    saveWebFormsToken({ webFormsToken }: { webFormsToken: string }): Promise<void>;
    getWebFormsToken(): Promise<string>;
}

export class MemoryRepositoryImpl implements MemoryRepository {
    private webFormsToken: string

    constructor() {
        this.webFormsToken = "";
     }

    async saveWebFormsToken({ webFormsToken }: { webFormsToken: string }): Promise<void> {
        this.webFormsToken = webFormsToken;
    }

    async getWebFormsToken(): Promise<string> {
        return this.webFormsToken;
    }
}