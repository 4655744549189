import { createSlice } from '@reduxjs/toolkit';
import { ModalProps } from '../models/modal-props.model';

const initialState: MainState = {
  showNotification: false,
  notificationMessage: null as unknown as string,
  modalProps: {
    isModalOpen: false,
    modalHeader: "",
    modalText: [],
    handleModalSubmit: () => {},
    modalPrimaryButtonText: "",
    modalSecondaryButtonEnabled: false,
    modalSecondaryButtonText: "",
    handleModalClose: () => {}, 
    handleModalSecondary: () => {},
    primaryButtonClasses: "",
    modalErrorsText: '',
  },
  isInDevelopmentMode: false,
  routePrefix: ""
};

const mainSlice = createSlice({
  name: 'main',
  initialState: initialState,
  reducers: {
    handleShowNotification(state, action) {
        state.showNotification = true;
        state.notificationMessage = action.payload;
    },
    handleHideNotification(state) {
        state.showNotification = false;
    },
    handleSetInDevelopmentMode(state) {
        state.isInDevelopmentMode = true;
    },
    handleSetRoutePrefix(state, action) {
      state.routePrefix = action.payload;
    },
    handleSetModalProps(state, action) {
      state.modalProps = action.payload
    },
    handleSetModalErrors(state, action) {
      state.modalProps.modalText = [action.payload];
      state.modalProps.modalPrimaryButtonText = 'Ok';
      state.modalProps.primaryButtonClasses = '';
      state.modalProps.modalSecondaryButtonEnabled = false;
      state.modalProps.handleModalSubmit = state.modalProps.handleModalClose;
    },
    handleHideModal(state) {
      state.modalProps.isModalOpen = false;
    },
  },
});

export interface MainState {
  //TODO: replace notification with modal props?
  showNotification: boolean;
  notificationMessage: string;
  modalProps: ModalProps;
  isInDevelopmentMode: boolean;
  routePrefix: string;
}

export const mainActions = mainSlice.actions;

export default mainSlice.reducer;
