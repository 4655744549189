import { PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { MemoryRepository } from './memory.repository';
import { EntityRetryDataSource } from '../datasources/entity-retry.datasource';
import { EntityFailureRecordModel } from '../../models/entity-failure.record.model';
import { ScimEntityType } from '../../models/scim-entity-type.model';

export type EntityRetryPaginated = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
};

export interface EntityRetryRepository {
  getEntityRetriesPaginated({ pageSize, pageNumber, sortingField, isAscending }: EntityRetryPaginated): Promise<PaginatedResponseModel<EntityFailureRecordModel>>;

  initiateRetry(entityId: string): Promise<void>;

  checkIfEntityExists({entityName, entityType}: {entityName: string, entityType: ScimEntityType}): Promise<string>;
}

export class EntityRetryRepositoryImpl implements EntityRetryRepository {
  constructor(private dataSource: EntityRetryDataSource, private memoryRepository: MemoryRepository) { }

  async getEntityRetriesPaginated({ pageSize, pageNumber, sortingField, isAscending }: EntityRetryPaginated): Promise<PaginatedResponseModel<EntityFailureRecordModel>> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();

      return await this.dataSource.getEntityRetriesPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken });
    } catch (error) {
      return null as unknown as PaginatedResponseModel<EntityFailureRecordModel>;
    }
  }

  async initiateRetry(entityId: string): Promise<void> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();

      return await this.dataSource.initiateRetry({ webFormsToken, entityId });
    } catch (error) {
      console.log(error)
    }
  }

  async checkIfEntityExists({entityName, entityType}: {entityName: string, entityType: ScimEntityType}): Promise<string> {
    try {
      const webFormsToken: string = await this.memoryRepository.getWebFormsToken();
      var res = await this.dataSource.checkIfEntityExists({ webFormsToken, entityName, entityType });
      return res;
    } catch (error) {
      console.log(error);
      return "";
    }
  }
}
