import { BaseEnum } from "./base.enum";

export class SyncStatus extends BaseEnum{
  public static readonly Configuring = new SyncStatus({id: 1, name: "Configuring"});
  public static readonly Queued = new SyncStatus({id: 2, name: "Queued"});
  public static readonly Processing = new SyncStatus({id: 3, name: "Processing"});
  public static readonly WaitingForOperations = new SyncStatus({id: 4, name: "Applying changes"});
  public static readonly Failed = new SyncStatus({id: 5, name: "Failed"});
  public static readonly Completed = new SyncStatus({id: 6, name: "Completed"});
  public static readonly CompletedWithErrors = new SyncStatus({id: 7, name: "Completed with errors"});
  public static readonly Canceled = new SyncStatus({id: 8, name: "Canceled"});
  public static readonly InSync = new SyncStatus({id: 9, name: "In Sync"});
  public static readonly Pending = new SyncStatus({id: 10, name: "Pending"});

  public static GetAll(): SyncStatus[] {return [
    this.Configuring, this.Queued, this.Processing, this.WaitingForOperations, this.Failed, this.Completed,
    this.CompletedWithErrors, this.Canceled, this.InSync, this.Pending
  ];}

  public static GetCompleted(): SyncStatus[]{return [
    this.Failed, this.Completed, this.CompletedWithErrors, this.Canceled
  ];}
}
