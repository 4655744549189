export const OktaIcon = ({color}: {color?:string}) => {
  const defaultColor = '#0488C1'
  return (
    <svg width="19px" height="18px" viewBox="0 0 19 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <title>okta icon</title>
      <defs>
        <polygon id="path-1" points="0 6.35906283e-06 17.9921729 6.35906283e-06 17.9921729 17.9900301 0 17.9900301"></polygon>
      </defs>
      <g id="design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="creds" transform="translate(-263.000000, -641.000000)">
          <g id="okta" transform="translate(190.500000, 634.000000)">
            <g id="okta-icon" transform="translate(73.000000, 7.000000)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="Clip-2"></g>
              <path d="M9.00403759,3.79706617 C6.13666917,3.75468271 3.88238346,6.1061188 3.7907594,8.79062256 C3.68587218,11.8641113 6.15065414,14.1293368 8.83003759,14.2032767 C11.8643684,14.2869835 14.1911504,11.8145549 14.2062719,9.03226917 C14.2218045,6.14699098 11.8435038,3.75168271 9.00403759,3.79706617 M8.99566917,17.9900408 C4.0423985,18.0000361 0,13.9499233 0,8.9954797 C0,4.03981805 4.04776692,-0.00621203008 9.00782707,6.3590628e-06 C13.9578271,0.00621654135 17.9986015,4.05082556 17.9921806,9.00766015 C17.9857218,13.9554045 13.9467293,18.0003519 8.99566917,17.9900408" id="Fill-1" fill={color ?? defaultColor} mask="url(#mask-2)"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
