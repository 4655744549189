import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import store from './store/index';
import { FeatureToggleService } from './app/datasources/feature-toggle.datasource';

const UserSyncSpa = {
  renderContent(elementId: string, splitFeatrueToggleService: FeatureToggleService|null = null) {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App featureToggleService={splitFeatrueToggleService}/>
          </BrowserRouter>
        </Provider>
      </React.StrictMode>,
      document.getElementById(elementId)
    );
  }
}

//@ts-ignore
window.UserSyncSpa = UserSyncSpa;

if (document.getElementById("content-us") != null) {
  UserSyncSpa.renderContent("content-us")
}
