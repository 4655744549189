const version = 'v1';

export function getApiBaseUrl(): string {
  // @ts-ignore
  const baseUrl = window.apiBaseUrl;

  if ((typeof baseUrl === 'string' || baseUrl instanceof String) && baseUrl.length > 0) {
    return `${baseUrl}/${version}`;
  }

  console.error('ERROR: "baseUrl" is not set.');
  return '';
}

export function getSyncProxyControllerUrl(): string {
  //@ts-ignore
  if (!!window.apiBaseUrl) {
    return `${getApiBaseUrl()}/proxy/scimapi`
  }
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:5000';
  }

  console.error('ERROR: "baseUrl" is not set.');
  return '';
}

export function getWebFormsUrl(): string {
  return window.location.origin;
}
