import { ScimEntityType } from "./scim-entity-type.model";

export interface EntityFailureRecordModel {
  createdAt: number;
  auditTrails: EntityFailureRecordAuditModel[];
  affectedEntityName: string;
  affectedEntityId: string;
  canRetry: boolean;
  reason: string;
  status: EntityFailureStatus;
  entityType: ScimEntityType;
}


export enum EntityFailureStatus {
  Failed = 1,
  InProgress = 2
}

export interface EntityFailureRecordAuditModel {
  timeStamp: number;
  auditId: string;
}