import { AxiosInstance } from "axios";
import { getWebFormsUrl } from "../../services/helper";


export interface WebformsTokenDataSource {
    getAuthenticationTokenFromWebForms(): Promise<string>;
}

export class WebformsTokenDataSourceImpl implements WebformsTokenDataSource {
    constructor(private client: AxiosInstance) { };

    async getAuthenticationTokenFromWebForms(): Promise<string> {
        try {
            const authTokenEndpoint = `${getWebFormsUrl()}/App_Services/AuthTokenService.asmx/GetAuthToken`;
            const response = await this.client.post(authTokenEndpoint, { withCredentials: true })
            var token = response.data.d.Token as unknown as string;
            return token as unknown as string;
          }
          catch (err) {
            throw new Error('Error retrieving auth token from webforms');
          };
    }
}

//@ts-ignore
export class WebformsTokenDataSourceMock implements WebformsTokenDataSource {
    async getAuthenticationTokenFromWebForms(): Promise<string> {
        return '';
    }
}