export interface FeatureToggleService {
    userSiteHasTreatment(user_site_split_name: string): Promise<boolean>;
    siteHasTreatment(site_split_name: string): Promise<boolean>;
    isFeatureEnabled(userSiteSplitName: string, siteSplitName: string): Promise<boolean>;
}

export class StubFeatureToggleService implements FeatureToggleService {
    public async userSiteHasTreatment(user_site_split_name: string): Promise<boolean> {
        return false;
    }

    public async siteHasTreatment(site_split_name: string): Promise<boolean> {
        return false;
    }

    public async isFeatureEnabled(userSiteSplitName: string, siteSplitName: string): Promise<boolean> {
        return false;
    }
}

export class FeatureToggles {
    public static readonly RetrySCIM = "USER_SYNC_RETRY_SCIM";
}