import { WebformsTokenDataSource } from "../datasources/webforms-token.datasource";
import { MemoryRepository } from "./memory.repository";

export interface WebformsTokenRepository {
  getAuthenticationTokenFromWebForms(): Promise<string>;
}

export class WebformsTokenRepositoryImpl implements WebformsTokenRepository {
  constructor(
    private dataSource: WebformsTokenDataSource,
    private memoryRepository: MemoryRepository,
    ) { }

  async getAuthenticationTokenFromWebForms(): Promise<string> {
    try {
      const webFormsToken: string = await this.dataSource.getAuthenticationTokenFromWebForms();
      await this.memoryRepository.saveWebFormsToken({webFormsToken});
      
      return webFormsToken;
    } catch (error) {
      return null as unknown as string;
    }
  }
}