import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthenticationTokenFromWebForms, getAuthorizationTokenByCredentials } from './store/auth-actions';
//import Authorize from './pages/Authorize';
import './App.scss';
import './pages/Global.scss';
import ModalWrapper from './components/modal-wrapper-component';
import { hideNotification, setInDevelopmentMode, setRoutePrefix } from './store/main-actions';
import { SyncSessionPage } from './pages/SyncSession.page';
import { ImportNavigationPanel } from './components/import-navigation-panel.component';
import { NewFileImportPage } from './pages/NewFileImport.page';
import { disableRedirect } from './store/import-actions';
import { FileImportPageTypes } from './models/enums/file-import-page-types.enum';
import { ModalProps } from './models/modal-props.model';
import { OktaPage } from './pages/Okta.page';
import { BreadcrumbComponent } from './components/breadcrumb.component';
import { AzurePage } from './pages/Azure.page';
import { ScimLogsPage } from './pages/ScimLogs.page';
import { FeatureToggleService } from './app/datasources/feature-toggle.datasource';
import container from './infrastructure';
import { HomePageTabs } from './components/home-page-tabs.component';

export interface AppProps {
  featureToggleService: FeatureToggleService|null;
}

const App = (props: AppProps) => {
  const isInDevelopmentMode = window.location.hostname === 'localhost' ? true : false;
  const RoutePrefix = isInDevelopmentMode ? '' : '/sync';
  const dispatch = useDispatch();
  //const [isAuthorizedLinkClicked, setIsAuthorizedLinkClicked] = useState(false);
  const location = useLocation();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get('code');
  const webFormsToken: string = useSelector((state: any) => state.auth.webFormsToken);

  if (!!props.featureToggleService) {
    container.setFeatureToggleService(props.featureToggleService);
  }

  /*
  function handleAuthorization(): void {
     setIsAuthorizedLinkClicked(true);
   }
  */

  //populate env. values to use globally
  useEffect((): void => {
    if (isInDevelopmentMode) {
      dispatch(setInDevelopmentMode())
    } else {
      dispatch(setRoutePrefix(RoutePrefix))
    }
  }, [dispatch, RoutePrefix, isInDevelopmentMode]);

  useEffect((): void => {
    if (code && webFormsToken && !isInDevelopmentMode) {
      dispatch(getAuthorizationTokenByCredentials(code));
    }
  }, [dispatch, code, webFormsToken, isInDevelopmentMode]);

  useEffect((): void => {
    if (!webFormsToken) {
      dispatch(getAuthenticationTokenFromWebForms());
    }
  }, [dispatch, webFormsToken, isInDevelopmentMode])

  useEffect((): void => {
    dispatch(disableRedirect());
  }, [dispatch, location]);

  //modal props
  const showNotification: boolean = useSelector((state: any) => state.main.showNotification);
  const notificationMessage: string = useSelector((state: any) => state.main.notificationMessage);
  const modalProps: ModalProps = useSelector((state: any) => state.main.modalProps);

  const handleModalClose = () => {
    dispatch(hideNotification());
  };

  return (
    <>
      {
        (webFormsToken?.length ?? 0) > 0 || isInDevelopmentMode
          ? <>
            <div className="sync-app">
              <main className="sync-app-main">
                <BreadcrumbComponent />
                <Switch>
                  <Route exact path={[`${RoutePrefix}/`,`${RoutePrefix}/entity-retries`, `${RoutePrefix}/sync-history`]}>
                    <div className="center top-panel">
                      <p id="app-header">User Sync</p>
                      <ImportNavigationPanel />
                    </div>
                    <HomePageTabs {...{routePrefix: RoutePrefix}} />
                  </Route>
                  {/* <Route path={`${RoutePrefix}/authorize`}>
                    <Authorize isAuthorized={isAuthorizedLinkClicked} />
                  </Route> */}
                  <Route path={`${RoutePrefix}/import/user*`} >
                    <NewFileImportPage {...{ schema: FileImportPageTypes.ImportUsers, pageHeader: "File Sync Users" }} />
                  </Route>
                  <Route path={`${RoutePrefix}/import/group*`} >
                    <NewFileImportPage {...{ schema: FileImportPageTypes.ImportGroups, pageHeader: "File Sync Groups" }} />
                  </Route>
                  <Route path={`${RoutePrefix}/session/:sessionId`} component={SyncSessionPage}></Route>
                  <Route path={`${RoutePrefix}/scim-logs`} component={ScimLogsPage} />
                  <Route exact path={`${RoutePrefix}/azure`} component={AzurePage} />
                  <Route exact path={`${RoutePrefix}/okta`} component={OktaPage} />

                  <Route path={`${RoutePrefix}/*`}>
                    <Redirect to={`${RoutePrefix}/`} />
                  </Route>
                </Switch>
                <ModalWrapper isOpen={showNotification} onClose={handleModalClose}>
                  <h2 className="sync-modal--title">Sync Scheduled</h2>
                  <p className="sync-modal--text">{notificationMessage}</p>
                  <div className="sync-modal--buttons">
                    <button className="buttonPrimary" onClick={handleModalClose}>OK</button>
                  </div>
                </ModalWrapper>
                <ModalWrapper isOpen={modalProps.isModalOpen} onClose={modalProps.handleModalClose}>
                  <h2 className="sync-modal--title">{modalProps.modalHeader}</h2>
                  <div>
                  {modalProps.modalErrorsText?.length > 0 &&
                  <p className="sync-modal--text sync-modal--errors">{modalProps.modalErrorsText}</p>
                  }
                  </div>
                  <div>
                    {modalProps?.modalText?.map(r => <p key={modalProps.modalText.indexOf(r)} className="sync-modal--text">{r}</p>)}
                  </div>
                  <div className="sync-modal--buttons">
                    <button
                      className={`${modalProps.primaryButtonClasses?.length > 0 ? modalProps.primaryButtonClasses : "buttonPrimary"}`}
                      onClick={modalProps.handleModalSubmit}
                      data-testid="buttonPrimary"
                    >
                      {modalProps.modalPrimaryButtonText}
                    </button>
                    {modalProps.modalSecondaryButtonEnabled &&
                    <button
                      className="buttonSecondary link-button"
                      onClick={modalProps.handleModalSecondary}
                      data-testid="buttonSecondary"
                    >
                      {modalProps.modalSecondaryButtonText}
                    </button>}
                  </div>
                </ModalWrapper>
              </main>
            </div>
          </>
          : <h3> Loading...Please wait</h3>
      }
    </>
  );
}

export default App;
