import { BaseEnum } from './base.enum';

export class ScimLogStatus extends BaseEnum {
  public static readonly Pending = new ScimLogStatus({ id: 1, name: 'Pending' });
  public static readonly Completed = new ScimLogStatus({ id: 11, name: 'Completed' });
  public static readonly Failed = new ScimLogStatus({ id: 12, name: 'Failed' });

  public static GetAll(): ScimLogStatus[] {return [ this.Pending, this.Completed, this.Failed ];}
}

export class ScimOperationType extends BaseEnum {
  public static readonly CreateUser = new ScimOperationType({ id: 1, name: 'Create User' });
  public static readonly UpdateUser = new ScimOperationType({ id: 2, name: 'Update User' });
  public static readonly DeleteUser = new ScimOperationType({ id: 3, name: 'Delete User' });
  public static readonly CreateGroup = new ScimOperationType({ id: 4, name: 'Create Group' });
  public static readonly UpdateGroup = new ScimOperationType({ id: 5, name: 'Update Group' });
  public static readonly DeleteGroup = new ScimOperationType({ id: 6, name: 'Delete Group' });
  public static readonly ArchiveUser = new ScimOperationType({ id: 7, name: 'Archive User' });
  public static readonly RestoreUser = new ScimOperationType({ id: 8, name: 'Restore User' });


  public static GetAll(): ScimLogStatus[] {return [ this.CreateUser, this.UpdateUser, this.DeleteUser, this.CreateGroup,
    this.UpdateGroup, this.DeleteGroup, this.ArchiveUser, this.RestoreUser ];}
}
