import container from '../infrastructure';
import { HttpResponseModel } from '../models/shared/http-response.model';
import { PaginatedResponseModel } from '../models/shared/paginated-response.model';
import { SyncLogRecord } from '../models/sync-log-record.model';
import { SyncSessionInfo } from '../models/sync-session-info.model';
import { hideModal, setModalErrors } from './main-actions';
import { syncSessionsActions } from './sync-sessions';

export const getSessionInfo = ({ sessionId }: { sessionId: string }) => {
  return async (dispatch: Function) => {
    try {
      const response: SyncSessionInfo = await container.syncSessionsRepository.getSessionInfo({ sessionId });
      dispatch(syncSessionsActions.handleGetSessionInfo(response));
    } catch (err) {
      console.error(`Error retrieving sync session info with message ${err}`);
    }
  };
};

export const getHistoryPaginated = ({ pageSize, pageNumber, sortingField, isAscending }:
  { pageSize: number, pageNumber: number, sortingField: string | null, isAscending: boolean, webFormsToken: string }) => {
  return async (dispatch: Function) => {
    try {
      const response: PaginatedResponseModel<SyncSessionInfo> = await container.syncSessionsRepository.getHistoryPaginated({ pageSize, pageNumber, sortingField, isAscending })

      if (response !== null) {
        dispatch(syncSessionsActions.handleGetHistoryPaginated(response));
      }
    } catch (err) {
      console.error(`Error retrieving sync sessions history with message ${err}`);
    }
  };
};

export const getLogsPaginated = ({ sessionId, pageSize, pageNumber, sortingField, isAscending }:
  { sessionId: string, pageSize: number, pageNumber: number, sortingField: string, isAscending: boolean, webFormsToken: string }) => {
  return async (dispatch: Function) => {
    try {
      const response: PaginatedResponseModel<SyncLogRecord> = await container.syncSessionsRepository.getSessionLogsPaginated({ sessionId, pageSize, pageNumber, sortingField, isAscending, });
      if (response !== null) {
        dispatch(syncSessionsActions.handleGetLogsPaginated(response));
      }
    } catch (err) {
      console.error(`Error retrieving sync session logs with message ${err}`);
    }
  };
};

export const abortSession = ({ sessionId }: { sessionId: string }) => {
  return async (dispatch: Function) => {
    try {
      const response: HttpResponseModel<string> = await container.syncSessionsRepository.abortSession({ sessionId });
      dispatch(reloadHistoryData());
      if (response.isSuccessful) {
        dispatch(hideModal());
      } else {
        dispatch(setModalErrors(response.data))
      }
    } catch (err) {
      console.error(`Error retrieving sync session logs with message ${err}`);
    }
  };
}

export const reloadHistoryData = () => {
  return async (dispatch: Function) => {
    dispatch(syncSessionsActions.handleReloadHistoryTrigger());
  }
}
