import container from '../infrastructure';
import { PaginatedResponseModel } from '../models/shared/paginated-response.model';
import { ScimLogsPaginated } from '../app/repositories/scim-history.repository';
import { scimHistoryActions } from './scim-history';
import { ScimLogRecord } from '../models/scim-log-record.model';
import { HttpResponseModel } from '../models/shared/http-response.model';
import { hideModal, setModalErrors } from './main-actions';
import { reloadHistoryData } from './sync-sessions-actions';
import { FeatureToggles } from '../app/datasources/feature-toggle.datasource';

export const getLogsPaginated = ({ pageSize, pageNumber, sortingField, isAscending }: ScimLogsPaginated) => {
  return async (dispatch: Function) => {
    try {
      const response: PaginatedResponseModel<ScimLogRecord> = await container.scimHistoryRepository.getScimLogsPaginated({ pageSize, pageNumber, sortingField, isAscending });
      if (response !== null) {
        dispatch(scimHistoryActions.handleGetLogsPaginated(response));
      }
    } catch (err) {
      console.error(`Error retrieving scim logs with message ${err}`);
    }
  };
};

export const cancelResumeScimSync = (enableProcessing: boolean ) => {
  return async (dispatch: Function) => {
    try {
      const response: HttpResponseModel<string> = await container.scimHistoryRepository.cancelResumeScimSync({ enableProcessing });

      if (response.isSuccessful) {
        dispatch(hideModal());
        dispatch(reloadHistoryData());
      } else {
        dispatch(setModalErrors(response.data));
      }
    } catch (err) {
      console.error(`Error cancel/resume scim sync with message ${err}`);
    }
  };
};

export const retryScimSync = (sessionId: string) => {
  return async (dispatch: Function) => {
    try {
      const response: HttpResponseModel<string> = await container.scimHistoryRepository.retryScimSync(sessionId);
      if (response.isSuccessful) {
        dispatch(hideModal());
        dispatch(reloadHistoryData());
      } else {
        dispatch(setModalErrors(response.data));
      }
    } catch (err) {
      console.error(`Error retrying scim sync with message ${err}`);
    }
  };
};

export const getScimRetryTreatment = () => {
  return async (dispatch: Function) => {
    try {
      const response: boolean = await container.featureToggleService.siteHasTreatment(FeatureToggles.RetrySCIM);
      dispatch(scimHistoryActions.handleGetRetryTreatment(response));
    } catch (err) {
      console.error(`Error checking feature treatment for ${FeatureToggles.RetrySCIM}: ${err}`);
    }
  };
};