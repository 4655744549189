import { createSlice } from '@reduxjs/toolkit';

const initialAuthState: AuthState = {
  token: '',
  authLink: '',
  webFormsToken: null as unknown as string
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    handleAuthorizationToken(state, action) {
      state.token = action.payload;
    },
    handleGettingAuthLink(state, action) {
      state.authLink = action.payload;
    },
    handleWebFormsToken(state, action) {
      state.webFormsToken = action.payload;
    },
  },
});

export interface AuthState {
  token: string;
  authLink: string;
  webFormsToken: string;
}

export const authActions = authSlice.actions;

export default authSlice.reducer;
