import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { NavTab } from "react-router-tabs";
import { FileImportForm } from "../components/file-import-form.component";
import { BaseEnum } from "../models/enums/base.enum";
import { FileImportPageTypes } from "../models/enums/file-import-page-types.enum";
import { SchemaType } from "../models/enums/schema-type.enum";
import './NewFileImport.scss';

export class FileImportPageTabs extends BaseEnum {
    public route: string | undefined;

    private constructor({ id, name, route }: { id: number, name: string, route?: string }) {
        super({ id, name });
        this.route = route;
    }

    public static readonly ImportUsers = new FileImportPageTabs({ id: 1, name: "Import Users", route: "users" })
    public static readonly ImportGroups = new FileImportPageTabs({ id: 2, name: "Import Groups", route: "groups" })
    public static readonly UpdateUsernames = new FileImportPageTabs({ id: 3, name: "Update Usernames", route: "usernames" })
    public static readonly UpdateGroupnames = new FileImportPageTabs({ id: 4, name: "Update Group Names", route: "groupnames" })
}

export const NewFileImportPage = ({ schema, pageHeader }: NewFileImportPageProps) => {
    const routePrefix = useSelector((state: any) => state.main.routePrefix);

    const tabsList = GetTabsListForSchema({ schema });
    const tabsElement = tabsList.map(t => <NavTab key={t.id} to={`${routePrefix}/import/${t.route}`}>{t.name}</NavTab>)
    const routesElements = GetTabsRoutesForSchema({ schema, routePrefix });
    return (
        <div className="file-import-page">
            <h3 className="header">{pageHeader}</h3>
            <div className="import-tabs">
                {tabsElement}
            </div>
            <div className="body">
                <Switch>
                    {routesElements}
                </Switch>
            </div>
        </div>
    )
}

function GetTabsListForSchema({ schema }: { schema: FileImportPageTypes }): FileImportPageTabs[] {
    let tabsToRender: FileImportPageTabs[] = [];
    switch (schema) {
        case FileImportPageTypes.ImportUsers: {
            tabsToRender = tabsToRender.concat([FileImportPageTabs.ImportUsers, FileImportPageTabs.UpdateUsernames])
            break;
        }
        case FileImportPageTypes.ImportGroups: {
            tabsToRender = tabsToRender.concat([FileImportPageTabs.ImportGroups, FileImportPageTabs.UpdateGroupnames])
            break;
        }
    }
    return tabsToRender;
}

function GetTabsRoutesForSchema({ schema, routePrefix }: { schema: FileImportPageTypes, routePrefix: string }): ReactElement[] {
    let routes: ReactElement[] = [];
    if (schema === FileImportPageTypes.ImportUsers) {
        routes.push(<Route exact key='users' path={`${routePrefix}/import/users`}>
            <FileImportForm {...{ importSchema: SchemaType.ImportUsers }} />
        </Route>);

        routes.push(<Route exact key='usernames' path={`${routePrefix}/import/usernames`}>
            <FileImportForm {...{ importSchema: SchemaType.UpdateUsername }} />
        </Route>);
    }

    if (schema === FileImportPageTypes.ImportGroups) {
        routes.push(<Route exact key='groups' path={`${routePrefix}/import/groups`}>
            <FileImportForm {...{ importSchema: SchemaType.ImportGroups }} />
        </Route>);
        routes.push(<Route exact key='groupnames' path={`${routePrefix}/import/groupnames`}>
            <FileImportForm {...{ importSchema: SchemaType.UpdateGroupName }} />
        </Route>);
    }

    routes.push(<Route>
        <Redirect key='redirect' to={`${routePrefix}/`} />
    </Route>);

    return routes;
}

export interface NewFileImportPageProps {
    schema: FileImportPageTypes;
    pageHeader: string;
}