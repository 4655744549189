import { PaginatedResponseModel } from '../../models/shared/paginated-response.model';
import { AxiosInstance } from 'axios';
import { getSyncProxyControllerUrl } from '../../services/helper';
import { EntityFailureRecordModel } from '../../models/entity-failure.record.model';
import { ScimEntityType } from '../../models/scim-entity-type.model';

export type EntityRetryPaginatedDataSource = {
  pageSize: number;
  pageNumber: number;
  sortingField: string | null;
  isAscending: boolean;
  webFormsToken: string;
};

export interface EntityRetryDataSource {
  getEntityRetriesPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken }: EntityRetryPaginatedDataSource): Promise<PaginatedResponseModel<EntityFailureRecordModel>>;
  initiateRetry({ webFormsToken, entityId }: { webFormsToken: string, entityId: string }): Promise<void>;
  checkIfEntityExists({webFormsToken, entityName, entityType}: {webFormsToken: string, entityName: string, entityType: ScimEntityType}) : Promise<string>;
}

export class EntityRetryDataSourceImpl implements EntityRetryDataSource {
  constructor(private client: AxiosInstance) { }

  async initiateRetry({ webFormsToken, entityId }: { webFormsToken: string, entityId: string }): Promise<void> {

    const id = entityId.split("#")[1];

    const endpointSufix = entityId.startsWith("user#")
      ? `users/${id}`
      : `groups/${id}`;

    const query = `${getSyncProxyControllerUrl()}/fix/${endpointSufix}`;
    const data = {};

    await this.client.put<any>(query, data, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
    });
  }

  async getEntityRetriesPaginated({ pageSize, pageNumber, sortingField, isAscending, webFormsToken }: EntityRetryPaginatedDataSource): Promise<PaginatedResponseModel<EntityFailureRecordModel>> {
    const query = `${getSyncProxyControllerUrl()}/entity-retry`;
    const data = {
      pageSize,
      pageNumber: pageNumber + 1,
      sortingField,
      isAscending,
    };

    const response = await this.client.post<any>(query, data, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
    });

    if (response.status === 200 || !!response.data) {
      const { data } = response;
      return {
        pageSize: data['pageSize'],
        pageIndex: data['pageNumber'],
        totalItemsCount: data['totalCount'],
        items: data['items'],
      };
    } else {
      throw new Error(`Error getting site operations. Status code: ${response.status}`);
    }
  }

  async checkIfEntityExists({webFormsToken, entityName, entityType}: {webFormsToken: string, entityName: string, entityType: ScimEntityType}) : Promise<string> {
    const entityTypeString = (entityType === ScimEntityType.User ? 'users' : 'groups');
    const filterName = (entityType === ScimEntityType.User ? 'userName' : 'displayName');
    const query = `${getSyncProxyControllerUrl()}/scim/${entityTypeString}`;

    const response = await this.client.get<any>(query, {
      headers: { Authorization: 'Bearer ' + webFormsToken },
      params: { filter: `${filterName} eq "${entityName}"`}
    });

    if (response.status === 200 && response?.data?.Resources?.length > 0) {
      return response.data.Resources[0].id;
    }
    else {
      return "";
    }
  }
}
